@font-face {
  font-family: 'icomoon';
  src: url('../../assets/fonts/icomoon.eot?vnui8z');
  src: url('../../assets/fonts/icomoon.eot?vnui8z#iefix')
      format('embedded-opentype'),
    url('../../assets/fonts/icomoon.woff2?vnui8z') format('woff2'),
    url('../../assets/fonts/icomoon.ttf?vnui8z') format('truetype'),
    url('../../assets/fonts/icomoon.woff?vnui8z') format('woff'),
    url('../../assets/fonts/icomoon.svg?vnui8z#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-down:before {
  content: '\e903';
}
.icon-arrow-up:before {
  content: '\e904';
}
.icon-arrow-left:before {
  content: '\e900';
}
.icon-arrow-right:before {
  content: '\e901';
}
.icon-linkedin:before {
  content: '\e902';
}
