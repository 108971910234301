:root {
  --pp-main: #707070;
  --pp-main-rgb: 112 112 112;

  --pp-black: #000;
  --pp-black-rgb: 0 0 0;

  --pp-white: #ffffff;
  --pp-white-rgb: 255 255 255;

  --pp-dark-blue: #0e1127;
  --pp-dark-blue-rgb: 14 17 39;

  --pp-blue: #3ab4ff;
  --pp-blue-rgb: 58 180 255;

  --pp-dark-gray: #343434;
  --pp-dark-gray-rgb: 52 52 52;

  --pp-light-gray: #dce1e4;
  --pp-light-gray-rgb: 220 225 228;

  --pp-font-main: var(--font-main);
}

.popup {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 8999;
  display: none;

  &.active {
    display: block;
  }

  &__close-bg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.65);
    z-index: -1;
    transition: all 0.5s ease;
    animation-duration: 0.3s;
    animation-name: opacityPopupBg;
  }

  &__block {
    --pp-default-translate-x-from: -50%;
    --pp-default-translate-x-to: -50%;

    --pp-default-translate-y-from: -50%;
    --pp-default-translate-y-to: -50%;

    --pp-opacity-from: 0;
    --pp-opacity-to: 1;

    background: var(--pp-white);

    position: absolute;
    top: 50%;
    left: 50%;

    width: 90%;
    max-width: ac(850px, 600px);
    padding: ac(40px, 30px) ac(40px, 24px);

    transform: translateY(var(--pp-default-translate-y-to))
      translateX(var(--pp-default-translate-x-to));
    transition: all 300ms ease-out;
    animation-duration: 0.6s;

    &.without-opacity {
      --pp-opacity-from: 1;
    }

    &.pos-l,
    &.pos-r {
      --pp-default-translate-x-from: 0;
      --pp-default-translate-x-to: 0;
    }

    &.pos-t,
    &.pos-b {
      --pp-default-translate-y-from: 0;
      --pp-default-translate-y-to: 0;
    }

    &.pos-t {
      top: 0;
      bottom: initial;
    }

    &.pos-r {
      right: 0;
      left: initial;
    }

    &.pos-b {
      top: initial;
      bottom: 0;
    }

    &.pos-l {
      right: initial;
      left: 0;
    }

    &.full-height {
      max-height: 100vh;
      height: 100%;
      right: 0;
      left: initial;
      --pp-default-translate-x-from: 0;
      --pp-default-translate-x-to: 0;

      .popup__content {
        max-height: calc(var(--vh, 1vh) * 72);
      }
    }

    &.slide-l {
      --pp-default-translate-x-from: 100%;
    }

    &.slide-r {
      --pp-default-translate-x-from: -100%;
    }

    &.slide-t {
      --pp-default-translate-y-from: -100%;
    }

    &.slide-b {
      --pp-default-translate-y-from: 100%;
    }

    &.slide-t,
    &.slide-r,
    &.slide-b,
    &.slide-l {
      animation-name: popupSlide;
    }
  }

  &__title {
    margin-bottom: ac(32px, 24px);
  }

  &__btn-close {
    position: absolute;
    right: ac(25px, 15px);
    top: ac(25px, 15px);
    transition: all 300ms ease-out;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    .close-clip {
      transition: inherit;
      width: ac(24px, 20px);
      height: ac(24px, 20px);
      background: var(--pp-dark-blue);
      clip-path: polygon(
        20% 0%,
        0% 20%,
        30% 50%,
        0% 80%,
        20% 100%,
        50% 70%,
        80% 100%,
        100% 80%,
        70% 50%,
        100% 20%,
        80% 0%,
        50% 30%
      );
    }

    i {
      transition: inherit;
      font-size: ac(24px, 20px);
      color: var(--pp-dark-blue);
    }

    &:hover {
      .close-clip {
        background: var(--pp-blue);
        transform: scale(1.05);
      }

      i {
        color: var(--pp-blue);
        transform: scale(1.05);
      }
    }
  }

  &__content {
    max-height: calc(var(--vh, 1vh) * 66);

    .simplebar-track.simplebar-vertical {
      width: 4px;
      background: var(--pp-light-gray);
      border-radius: 4px;
      transform: translateX(10px);

      .simplebar-scrollbar {
        background: var(--pp-dark-blue);
        border-radius: 4px;

        &:before {
          content: none;
        }
      }
    }

    .popup-form {
      &__container {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-column-gap: 15px;
        grid-row-gap: 15px;

        @mixin max-sm {
          grid-template-columns: repeat(1, minmax(0, 1fr));
        }
      }

      &__bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 18px;
        padding-top: ac(24px, 20px);
        flex-wrap: wrap;

        .popup-btn {
          margin-left: auto;
        }
      }
    }

    .popup-btn {
      font-size: ac(16px, 14px);
      font-weight: 700;
      font-family: var(--pp-font-main);
      line-height: 1.2;
      text-transform: uppercase;
      background-color: var(--pp-dark-blue);
      color: var(--pp-white);
      border: none;
      outline: none;
      border-radius: 4px;
      padding: 12px 18px;
      cursor: pointer;
      transition: 0.3s ease-in-out all;
      position: relative;
      overflow: hidden;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transform: skew(45deg);
        pointer-events: none;
      }

      &:hover {
        &::after {
          background-image: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0) 40%,
            rgba(255, 255, 255, 0.7) 100%
          );
          animation: gradientPopupBtn 0.3s linear forwards;
        }
      }

      &:active {
        transform: scale(0.9);
        opacity: 0.9;
      }
    }
  }
}

@keyframes popupSlide {
  from {
    transform: translateY(var(--pp-default-translate-y-from))
      translateX(var(--pp-default-translate-x-from));
    opacity: var(--pp-opacity-from);
  }

  to {
    transform: translateY(var(--pp-default-translate-y-to))
      translateX(var(--pp-default-translate-x-to));
    opacity: var(--pp-opacity-to);
  }
}

@keyframes opacityPopupBg {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes gradientPopupBtn {
  0% {
    transform: translateX(-100%) skew(45deg);
  }

  100% {
    transform: translateX(100%) skew(45deg);
  }
}

.popup-candidate-alert,
.popup-candidate-alert-profile,
.popup-request-info,
.popup-request-interview,
.popup-request-cv,
.refer-a-candidate,
.brochures-popup,
.refer-a-friend,
.popup-cv-upload,
.popup-apply-now {
  padding: 0;
  max-width: 845px;
  background-color: var(--cl-blue);

  .popup__title {
    color: var(--cl-white);
  }

  .footer__form {
    max-width: unset;

    .title, .thanks, .title-small {
      color: var(--cl-white);
      margin: 0 auto ac(51px, 20px, 320, 1440);
    }
  }

  .popup-services__close {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='25' fill='none'%3E%3Cpath fill='%23FFF' d='M20.955 25c-.324 0-.632-.046-.924-.137-.26-.122-.519-.335-.778-.64l-8.508-9.735-1.653-1.508-8.46-9.644C.243 2.88.032 2.392 0 1.874 0 1.356.162.914.486.548.843.183 1.346 0 1.993 0c.713 0 1.28.229 1.702.686l8.265 9.506 1.264 1.051 9.14 10.512c.454.518.665 1.036.633 1.554-.033.488-.243.899-.632 1.234-.39.305-.86.457-1.41.457ZM2.188 25c-.616 0-1.102-.168-1.459-.503a1.83 1.83 0 0 1-.535-1.325c0-.518.211-1.02.633-1.508l8.508-10.01 2.43 3.2-7.973 9.46c-.421.457-.956.686-1.604.686Zm11.766-11.792-3.015-2.559L18.961.777c.26-.305.519-.503.778-.594.292-.122.616-.183.973-.183.648 0 1.134.198 1.458.594.357.366.519.823.486 1.371 0 .518-.21 1.021-.632 1.508l-8.07 9.735Z'/%3E%3C/svg%3E");

    &:hover {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='25' fill='none'%3E%3Cpath fill='%23E4B700' d='M20.955 25c-.324 0-.632-.046-.924-.137-.26-.122-.519-.335-.778-.64l-8.508-9.735-1.653-1.508-8.46-9.644C.243 2.88.032 2.392 0 1.874 0 1.356.162.914.486.548.843.183 1.346 0 1.993 0c.713 0 1.28.229 1.702.686l8.265 9.506 1.264 1.051 9.14 10.512c.454.518.665 1.036.633 1.554-.033.488-.243.899-.632 1.234-.39.305-.86.457-1.41.457ZM2.188 25c-.616 0-1.102-.168-1.459-.503a1.83 1.83 0 0 1-.535-1.325c0-.518.211-1.02.633-1.508l8.508-10.01 2.43 3.2-7.973 9.46c-.421.457-.956.686-1.604.686Zm11.766-11.792-3.015-2.559L18.961.777c.26-.305.519-.503.778-.594.292-.122.616-.183.973-.183.648 0 1.134.198 1.458.594.357.366.519.823.486 1.371 0 .518-.21 1.021-.632 1.508l-8.07 9.735Z'/%3E%3C/svg%3E");
    }
  }

  .footer__upload-box {
    width: 100%;
    margin-right: 0;
  }

  .footer__btn {
    margin-bottom: ac(51px, 20px, 320, 1440);
  }

  .agreement {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(100% - 150px);

    .checkBox {
      display: flex;
      position: relative;
      width: 100%;

      input {
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        visibility: hidden;
      }
      input:checked + span:before {
        background: var(--cl-yellow);
      }

      span {
        padding-left: ac(35px, 30px);
        margin-bottom: 0;
        color: var(--cl-white);
        cursor: pointer;

        a {
          display: inline-block;
          color: var(--cl-white);
          text-decoration: underline;

          &:hover {
            color: var(--cl-yellow);
          }
        }

        &::before {
          content: '';
          background: transparent;
          border: 1px solid var(--cl-white);
          box-sizing: border-box;
          content: "";
          height: 20px;
          left: 0;
          position: absolute;
          top: 1px;
          transition: all .3s ease-out;
          width: 20px;
          cursor: pointer;
        }
      }
    }
  }

  #cv_template,
  #popup-candidate-alert,
  #popup-candidate-alert-profile,
  #popup-request-info,
  #popup-request-interview,
  #popup-request-cv,
  #refer_candidate,
  #popup-brochures,
  #refer_friend,
  #popup-cv-upload,
  #popup-apply-now {
    max-height: 85vh;
    width: 100%;
    padding: 51px ac(60px, 10px, 320, 1440) 0 ac(61px, 10px, 320, 1440);

    p {
      color: var(--cl-white);
    }

    .simplebar-track.simplebar-vertical {
      width: 6px;
      right: 0px;
      background-color: rgba(228, 183, 0, .2);
    }

    .simplebar-scrollbar::before {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: var(--cl-yellow);
      opacity: 1;
      border-radius: 0;
    }
  }

  @mixin max-lg {
    .footer__upload-box {
      flex-direction: row;
      justify-content: flex-start;
    }

    .footer__upload-text {
      margin: 0 10px;
    }
  }

  @mixin max-sm {
    .agreement {
      width: 100%;
    }

    .footer__upload-box {
      justify-content: center;
    }
  }
}

