.footer {
	position: relative;
	margin-top: ac(100px, 60px);
	padding: 60px 0 ac(60px, 30px);
	background-color: var(--cl-tealish);

	&__target {
		position: absolute;
		left: 0;
		right: 0;
		top: ac(40px, 34px);
		z-index: 1;
	}

	#contact_us {
		.choices__list--dropdown {
			.choices__item:first-child {
				display: none;
			}
		}
	}

	&--detail {
		margin-top: ac(108px, 60px);
	}

	&--alt {
		&::before {
			content: '';
			position: absolute;
			left: 50%;
			top: ac(-102px, -40px);
			transform: translateX(-50%);
			width: calc(100% - 40px);
			height: 2px;
			max-width: 1276px;
			background-color: var(--cl-tealish);
		}
	}

	&--detail,
	&--alt {
		.footer__bottom {
			margin-top: 0;
		}
	}
	
	.cont {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
	}

	&__wrapper {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
	}

	&__logo {
		position: relative;
		width: 196px;
		height: 68px;
		margin-bottom: ac(60px, 40px);
		margin-right: calc(100% - 196px);

		img {
			position: absolute;
			object-fit: contain;
			transition: 0.3s ease;

			&:nth-child(2) {
				opacity: 0;
			}
		}

		&:hover {
			img {
				&:nth-child(1) {
					opacity: 0;
				}

				&:nth-child(2) {
					opacity: 1;
				}
			}
		}
	}

	&__title-box {
		width: 100%;
		display: flex;
		flex-direction: column;
		margin-top: ac(-113px, -60px, 768, 1440);
		margin-bottom: ac(40px, 25px);
	}

	&__descr {
		color: var(--cl-white);
		font-weight: 600;
	}

	&__title {
		color: var(--cl-yellow);
		flex-shrink: 0;
		font-size: ac(30px, 22px);
		line-height: ac(36px, 26px);
		margin-bottom: ac(13px, 10px);
		font-weight: 600;
	}

	&__contacts {
		display: flex;
		flex-direction: column;
		max-width: ac(304px, 240px);
		flex-shrink: 1;
		width: 100%;
		margin-right: auto;
	}

	&__contact {
		padding-bottom: 17px;
		margin-bottom: ac(40px, 28px);
		border-bottom: 2px solid var(--cl-yellow);
	}

	&__suptitle {
		font-weight: 600;
		font-size: ac(20px, 16px);
		line-height: normal;
		color: var(--cl-yellow);
		margin-bottom: ac(10px, 5px);

		@mixin max-sm {
			font-weight: 700;
		}
	}

	&__link {
		color: var(--cl-white);
		font-size: ac(30px, 24px);
		font-weight: 600;
		line-height: 140%;
		transition: .3s ease;

		&:hover {
			color: var(--cl-yellow);
		}

		&--sm {
			font-size: ac(22px, 20px);
		}

		@mixin max-sm {
			font-weight: 700;
			font-weight: 150%;
		}
	}

	&__soc-box {
		display: flex;
		align-items: flex-end;
	}

	&__soc {
		margin-right: ac(46px, 28px);
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		transition: .3s ease;

		&--in {
			width: 24px;
			height: 23px;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='23' fill='none'%3E%3Cpath fill='%23E4B700' d='M4.98 2.5A2.49 2.49 0 0 1 2.5 5 2.49 2.49 0 0 1 .02 2.5C.02 1.12 1.13 0 2.5 0a2.49 2.49 0 0 1 2.48 2.5ZM5 7H0v16h5V7Zm7.982 0H8.014v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0V23H24V12.869c0-7.88-8.922-7.593-11.018-3.714V7Z'/%3E%3C/svg%3E");

			&:hover {
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='23' fill='none'%3E%3Cpath fill='%23fff' d='M4.98 2.5A2.49 2.49 0 0 1 2.5 5 2.49 2.49 0 0 1 .02 2.5C.02 1.12 1.13 0 2.5 0a2.49 2.49 0 0 1 2.48 2.5ZM5 7H0v16h5V7Zm7.982 0H8.014v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0V23H24V12.869c0-7.88-8.922-7.593-11.018-3.714V7Z'/%3E%3C/svg%3E");
			}
		}

		&--x {
			width: 22px;
			height: 20px;
			background-image: url("data:image/svg+xml,%3Csvg width='22' height='20' viewBox='0 0 22 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.99 0H20.298L13.071 8.26L21.573 19.5H14.916L9.702 12.683L3.736 19.5H0.426L8.156 10.665L0 0H6.826L11.539 6.231L16.99 0ZM15.829 17.52H17.662L5.83 1.876H3.863L15.829 17.52Z' fill='%23E4B700'/%3E%3C/svg%3E%0A");

			&:hover {
				background-image: url("data:image/svg+xml,%3Csvg width='22' height='20' viewBox='0 0 22 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.99 0H20.298L13.071 8.26L21.573 19.5H14.916L9.702 12.683L3.736 19.5H0.426L8.156 10.665L0 0H6.826L11.539 6.231L16.99 0ZM15.829 17.52H17.662L5.83 1.876H3.863L15.829 17.52Z' fill='%23fff'/%3E%3C/svg%3E%0A");
			}
		}
	}

	&__form {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		max-width: ac(844px, 470px);
		width: 100%;

		.thanks {
			color: var(--cl-white);
			margin: 0 auto;

			@mixin md {
				margin: ac(200px, 150px) auto 0;
			}
		}
	}

	&__form--second {
		@media only screen and (min-width: 1280px) and (max-width: 1700px) {
			.footer__btn-box {
				flex-direction: column;
				grid-row-gap: 32px;

				#payment_form_submit_form {
					margin-left: 0;
				}
			}
		}
	}

	&__input-box {
		width: calc(50% - ac(10px, 5px));
		display: flex;
		flex-wrap: wrap;
		margin-bottom: ac(29px, 20px);

		&>input, &>.choices, &>.footer__label {
			width: 100%;
		}

		&--area {
			width: 100%;
		}
	}

	&__label {
		padding-bottom: ac(15px, 10px);
		font-size: ac(20px, 16px);
		color: var(--cl-yellow);
		font-weight: 600;
		line-height: normal;

		@mixin max-sm {
			font-weight: 700;
		}

		&+* {
			margin-top: auto;
		}

		span {
			font-size: .7em;
		}
	}

	&__rating {
		input:checked + label ~ label:before {
			color: var(--cl-tealish);
		}

		&:hover {
			.footer__star:before {
				color: var(--cl-yellow);
			}
			.footer__star:hover ~ .footer__star:before {
				color: var(--cl-tealish);
			}
		}
	}

	&__star {
		cursor: pointer;

		&:before {
			content: "★";
			display: inline-block;
			font-size: ac(65px, 40px);
			line-height: ac(55px, 40px);
			letter-spacing: 10px;
			color: var(--cl-yellow);
			-webkit-text-stroke: 2px var(--cl-yellow);
			transition: .3s ease;
		}
	}

	&__btn-box {
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
		width: 100%;
	}

	&__upload-box {
		position: relative;
		display: flex;
		align-items: flex-start;
		cursor: pointer;
		margin-right: 20px;
		width: fit-content!important;
		input {
			position: absolute;
			opacity: 0;
			visibility: hidden;
			z-index: -1;
		}

		div {
			margin-right: 15px;
			border-radius: 24px;
			padding: 0 ac(20px, 10px);
			line-height: 44px;
			font-weight: 600;
			border: 2px solid var(--cl-white);
			color: var(--cl-white);
			transition: .3s ease;
		}

		&:hover {
			div {
				background-color: var(--cl-yellow);
			}

			.footer__upload-text {
				color: var(--cl-yellow);
			}
		}
	}

	&__upload-text {
		line-height: 48px;
		font-weight: 600;
		color: var(--cl-white);
		transition: .3s ease;

		max-width: ac(340px, 320px);
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		@media (max-width: 768px) {
			max-width: ac(320px, 200px, 768, 360);
		}
		&.cv_file_name {
			max-width: ac(340px, 200px);
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	&__btn {
		margin-left: auto;
	}

	&__bottom {
		padding: ac(40px, 20px) 0 0;
		display: flex;
		flex-wrap: wrap;
		border-top: 2px solid var(--cl-white);
		margin-top: ac(57px, 30px);
	}
	a.footer__copy {
		transition: .3s ease;

		&:hover {
			opacity: 1;
			color: var(--cl-yellow);
		}
	}
	&__copy {
		margin-right: ac(30px, 10px);
		font-size: ac(16px, 12px);
		letter-spacing: -0.01em;
		
		@media (max-width: 1200px) {
			margin-right: 10px;
		}

		@media (max-width: 540px) {
			font-size: 14px;
		}

		&:last-child {
			margin-right: 0px;
			margin-left: auto;

			@media (max-width: 1200px) {
				margin-right: 0;
			}
		}

		color: var(--cl-white);
		opacity: .5;
	}

	&__google-box {
		color: var(--cl-white);
		margin-right: ac(30px, 10px);
	}

	&__google-rate, &__google-title {
		font-size: ac(16px, 14px);
		opacity: .5;
	}

	&__stars {
		margin-left: 27px;

		span {
			position: relative;
			&::before {
				content: "★";
				display: inline-block;
				color: var(--cl-yellow);
				font-size: 20px;
				line-height: 18px;
			}
		}
	}

	@mixin max-xl {
		&__bottom {
			margin-bottom: ac(-15px, -5px);
		}
	}

	@mixin max-lg {
		&__bottom {
			flex-direction: column;
			align-items: center;
		}

		&__upload-box {
			flex-direction: column;
			align-items: center;

			div {
				margin-right: 0;
			}
		}

		&__google-box {
			margin-right: 0;
			margin-bottom: 10px;
		}

		&__stars {
			margin-left: 0;
		}

		&__copy {
			margin-right: 0;
			text-align: center;

			&:not(:last-child) {
				margin-bottom: 7px;
			}

			&:last-child {
				margin-right: 0px;
				margin-left: 0px;
			}
		}
	}

	@mixin max-md {
		&--detail,
		&--alt {
			.footer__logo {
				margin-top: 0;
			}
		}

		&__title-box {
			margin-bottom: 35px;
			margin-top: 0;
		}

		&__form {
			max-width: unset;
		}

		&__contacts {
			order: 1;
			flex-direction: row;
			flex-wrap: wrap;
			max-width: unset;
			justify-content: space-between;
		}

		&__logo {
			order: 1;
			margin: 68px auto 40px;
		}

		&__contact {
			width: calc(50% - 10px);
		}

		&__upload-box {
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;
		}

		&__upload-text {
			margin: 0 10px;
		}

	}

	@mixin max-sm {
		&__title-box {
			flex-direction: column;
		}

		&__title {
			margin-right: 0;
		}

		&__descr {
			margin-top: 5px;
		}

		&__contact, &__input-box {
			width: 100%;
		}

		&__upload-box {
			width: 100%;
			margin-right: 0;

			div {
				width: 100%;
				padding: 0 55px;
				text-align: center;
			}
		}

		&__btn {
			width: 100%;
			margin-top: 30px;

			&.btn {
				.arrow-btn {
					margin-right: -20px;
				}
			}

			.btn__text {
				margin: 0 auto;
			}
		}
	}

	@mixin max-xs {
		&__upload-box div {
			padding: 0 15px;
		}
	}
}
