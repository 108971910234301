.btn-box {
    position: fixed;
    right: 0;
    top: 25%;
    z-index: 20;

    display: flex;
    flex-direction: column;
    pointer-events: none;
    transition: .3s ease;

    @media (max-width: 1281px) {
        display: none;
    }

    &.is-hide {
        opacity: 0;
        visibility: hidden;
        transform: translateX(100%);
    }

    &__btn {
        position: relative;
        margin: 16px 0;
        padding: 0 10px 0 20px;

        backdrop-filter: blur(15px);
        background: rgba(234, 237, 246, .3);
        filter: drop-shadow(2px 4px 6px black);
        font-size: 14px;
        font-weight: 600;
        line-height: 43px;
        color: var(--cl-white);
        transition: .3s ease;
        pointer-events: all;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 10px;
            background-color: var(--cl-yellow);
            transition: .3s ease;
        }

        span {
            transition: .3s ease;
        }

        &:hover {
            padding: 0 20px 0 10px;
            background: rgba(228, 183, 0, .5);

            &::before {
                left: calc(100% - 10px);
            }
        }

        &.is-hide {
            color: rgba(255, 255, 255, 0);
            transform: translateX(90px);

            &:not(:last-child) {
                span {
                    transform: translateX(-90px);
                }
            }

            &:last-child {
                span {
                    transform: translateX(-90px);
                }
            }

            span {
                display: inline-block;
                color: var(--cl-white);

                &.not-transform {
                    transform: translateX(0px);
                }
            }

            &:hover {
                transform: translateX(0px);
                color: var(--cl-white);

                span {
                    transform: translateX(0px);
                }
            }
        }
    }
}

.slider-nav {
    margin-left: auto;
    display: flex;
    user-select: none;
    z-index: 2;

    &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        border: 2px solid var(--cl-white);
        border-radius: 100%;
        background-color: var(--cl-white);
        transition: .3s ease;
        cursor: pointer;

        &:first-child {
            margin-right: ac(20px, 8px);
        }

        &.swiper-button-lock {
            display: none !important;
        }

        &.swiper-button-disabled {
            pointer-events: none;
            opacity: .5;
        }

        &:hover {
            background-color: var(--cl-yellow);

            .slider-nav__icon {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19' height='21' fill='none'%3E%3Cpath stroke='%23fff' stroke-linejoin='bevel' stroke-width='3' d='m10.934 19.486-9.028-9.028 9.028-9.028m7.8 9.484H2.32'/%3E%3C/svg%3E");

                &--next {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19' height='21' fill='none'%3E%3Cpath stroke='%23fff' stroke-linejoin='bevel' stroke-width='3' d='m8.066 1.514 9.028 9.028-9.028 9.027m-7.8-9.483H16.68'/%3E%3C/svg%3E");
                }
            }
        }

        @mixin max-xs {
            width: 40px;
            height: 40px;
        }
    }

    &__icon {
        width: 19px;
        height: 21px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19' height='21' fill='none'%3E%3Cpath stroke='%23061654' stroke-linejoin='bevel' stroke-width='3' d='m10.934 19.486-9.028-9.028 9.028-9.028m7.8 9.484H2.32'/%3E%3C/svg%3E");
        transition: .3s ease;

        &--next {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19' height='21' fill='none'%3E%3Cpath stroke='%23061654' stroke-linejoin='bevel' stroke-width='3' d='m8.066 1.514 9.028 9.028-9.028 9.027m-7.8-9.483H16.68'/%3E%3C/svg%3E");
        }
    }
    
    &--dark {
        .slider-nav__btn {
            border: 2px solid var(--cl-gray);
            background-color: var(--cl-gray);

            &:hover {
                background-color: var(--cl-yellow);
            }
        }
    }
}

.arrow-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    border: 2px solid var(--cl-tealish);
    border-radius: 100%;
    background-color: var(--cl-dark-blue);
    transition: .3s ease;

    &__icon {
        width: 16px;
        height: 16px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none'%3E%3Cpath stroke='%23E4B700' stroke-linejoin='bevel' stroke-width='3' d='M1 2.384h12.766V15.15m-12.22-1.193L13.155 2.351'/%3E%3C/svg%3E");
        transition: .3s ease;
    }

    &--wh {
        background-color: var(--cl-white);
        border: 2px solid var(--cl-white);
    }

    @mixin max-xs {
        width: 40px;
        height: 40px;
    }
}

.btn {
    display: inline-flex;
    border: 2px solid var(--cl-tealish);
    border-radius: 24px;
    transition: .3s ease;
    cursor: pointer;

    .arrow-btn {
        margin: -2px 12px -2px -2px;
    }
    
    &__text {
        font-weight: 600;
        line-height: 44px;
        margin-right: 18px;
        transition: .3s ease;

        &.with-small-text {
            @media (max-width: 451px) {
                font-size: 13px;
                letter-spacing: -0.02em;
                margin-right: 14px;
                margin-left: -1px;
            }
        }
    }

    &:hover {
        background-color: var(--cl-tealish);

        .arrow-btn {
            background-color: var(--cl-white);

        }

        .arrow-btn__icon {
            transform: rotate(45deg);
        }

        .btn__text {
            color: var(--cl-white);
        }
    }

    &--wh {
        border-color: var(--cl-white);

        .arrow-btn {
            background-color: var(--cl-white);
            border-color: var(--cl-white);
        }

        .btn__text {
            color: var(--cl-white);
        }

        &:hover {
            background-color: var(--cl-yellow);
        }
    }

    @mixin max-xs {
        &__text {
            line-height: 36px;
            margin-right: 10px;
        }

        .arrow-btn {
            margin-right: 5px;
        }
    }

    @media (max-width: 360px) {
       .btn__text {
           font-size: 14px;

           &.with-small-text {
               font-size: 11.2px;
           }
       }
    }

    @media (max-width: 340px) {
        .btn__text {
            font-size: 13px;
        }
    }
}