.map {
    margin-top: ac(160px, 60px);

    .country {
        user-select: none;
        cursor: pointer;
        
        &.empty {
            pointer-events: none;
        }
    }

    &::before, &::after {
        content: '';
        position: absolute;
        left: 50%;
        top: ac(-60px, -20px);
        transform: translateX(-50%);
        width: calc(100% - 40px);
        height: 2px;
        max-width: 1276px;
        background-color: var(--cl-tealish);
    }
    
    &::after {
        top: unset;
        bottom: ac(-60px, -20px);
        z-index: 2;
    }

    &__wrapper {
        margin-top: ac(-100px, -60px, 1024, 1440);
        display: flex;
        align-items: flex-start;
    }

    &__box {
        position: relative;
        flex-shrink: 0;
        width: 100%;
        max-width: ac(550px, 450px, 1024, 1440);
        height: ac(450px, 420px, 1024, 1440);
        margin-right: ac(108px, 0px, 1200, 1440);

        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 70px;
            top: ac(500px, 410px, 1024, 1440);
            right: 0;
            background-color: var(--cl-white);
            z-index: 1;
        }
    }

    &__title {
        position: relative;
        display: inline-block;
        z-index: 1;
        background-color: var(--cl-white);
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        width: 100%;
        margin-right: ac(-55px, -15px);
    }

    &__descr {
        position: relative;
        width: 100%;
        font-size: ac(30px, 22px);
        font-weight: 600;
        margin-bottom: ac(40px, 20px);

        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            max-width: 450px;
            height: 2px;
            background-color: var(--cl-tealish);
        }
    }

    &__inner {
        width: ac(1300px, 1000px, 1024, 1440);
        height: ac(910px, 700px, 1024, 1440);
        transform: translate(ac(-240px, -230px, 1024, 1440) , ac(10px, 30px, 1024, 1440));

        .country {
            &.active {
                fill: var(--cl-yellow) !important;
            }
        }
    }

    &__country-box {
        display: flex;
        align-items: center;

        &.active, &:hover {
            .map__country {
                color: var(--cl-yellow);
            }
        }

        &--remote {
            .map__flag {
                position: relative;
                transform: translateY(-2px);

                img {
                    transition: .3s ease;

                    &:nth-child(2) {
                        position: absolute;
                        top: 0;
                        opacity: 0;
                    }
                }
            }

            &:hover {
                .map__flag {
                    img {

                        &:nth-child(1) {
                            opacity: 0;
                        }
                        
                        &:nth-child(2) {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    &__country-list {
        position: relative;
        width: 100%;

        column-gap: ac(15px, 5px, 1024, 1440);
        column-count: 3;
        padding-bottom: 20px;
        margin-bottom: ac(30px, 20px, 1024, 1440);
        
        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            max-width: 450px;
            height: 2px;
            background-color: var(--cl-tealish);
        }

        .map__country-box {
            margin-bottom: ac(15px, 10px);
        }
    }

    &__country {
        font-size: ac(26px, 18px);
        font-weight: 600;
        line-height: 117%;
        text-transform: capitalize;
        transition: .3s ease;

        @mixin max-sm {
            font-weight: 700;
            line-height: 169%;
        }
    }

    &__flag {
        position: relative;
        margin-right: ac(10px, 5px, 1024, 1440);
        flex-shrink: 0;
        width: 30px;
        height: 31px;
        transition: .3s ease;
        
        img {
            object-fit: contain;
            object-position: top;
        }
    }

    @mixin max-lg {
        &__wrapper {
            margin-top: 20px;
            flex-direction: column;
        }

        &__box, &__title br {
            display: none;
        }

        &__descr {
            width: 100%;
        }
    }

    @mixin max-md {
        &::after {
            display: none;
        }

        &__btn {
            .arrow-btn {
                margin-right: 5px;
            }
        }
    }

    @mixin max-sm {
        &__country-list {
            column-count: 2;
        }

    }

    @mixin max-xs {
        &__country {
            font-size: 16px;
        }

        &__flag {
            width: 20px;
            height: 21px;
        }
    }
}