.memes-section {
  padding-top: ac(250px, 105px, 375, 1440);


  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__top {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 1px solid var(--cl-dark-blue);

    &:not(:last-child) {
      margin-bottom: ac(10px, 8px);
    }

    @media (max-width: 551px) {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 60%;
    padding-bottom: ac(30px, 24px);

    @media (max-width: 551px) {
      width: 100%;
    }
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(30px, 24px);
    }
  }

  &__text {
    font-size: ac(32px, 22px);
    line-height: 1.4;

    p, li {
      font-size: ac(32px, 22px);
      line-height: 1.4;
    }
  }

  &__sort {
    padding-top: ac(20px, 16px);
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .choices {
      &__inner {
        .choices__item {
          white-space: nowrap;
        }
      }
    }

    .choices__list--dropdown .choices__item--selectable[data-select-text], .choices__list[aria-expanded] .choices__item--selectable[data-select-text] {
      padding-right: 20px;
      white-space: nowrap;
    }

    .simplebar-track.simplebar-horizontal {
      display: none !important;
    }
  }

  &__image {
    @mixin aspect-ratio 392, 378;
    width: 35%;
    margin-top: auto;
    max-width: 392px;


    img {
      object-fit: contain;
      object-position: bottom;
    }

    @media (max-width: 551px) {
      width: 100%;
    }
  }

  &__result {
    padding-top: ac(30px, 24px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
  }

  &__result-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-column-gap: ac(30px, 16px);
    grid-row-gap: ac(30px, 20px);

    .job-results-title {
      white-space: nowrap;
    }

    @media (max-width: 640px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  &__item {
    padding-top: 16px;
    border-top: 1px solid var(--cl-dark-blue);
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    cursor: pointer;

    &:hover {
      .memes-section__item-image {
        img {
          transform: scale(1.1);
        }
      }
    }
  }

  &__item-image {
    @mixin aspect-ratio 445, 310;
    width: 100%;
    overflow: hidden;
    border: 1px solid var(--cl-black);

    img {
      transition: .3s;
      object-fit: contain;
    }
  }

  &__result-more {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .btn {
      &__text {
        margin: 0;
        padding-left: 16px;
        padding-right: 16px;
      }
    }
  }
}