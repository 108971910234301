.benefits {
    margin-top: ac(97px, 30px);
    padding: ac(60px, 30px) 0;

    &::after, &::before {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 40px);
        height: 2px;
        max-width: 1276px;
        background-color: var(--cl-tealish);
    }

    &::before {
        top: 0;
    }

    &::after {
        bottom: 0;
    }

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
    }

    &__card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: ac(41px, 20px) 0 ac(31px, 20px);
        border-top: 2px solid var(--cl-tealish);
        border-bottom: 2px solid var(--cl-tealish);

        &:nth-child(3n + 1) {
            .benefits__icon {
                width: ac(66px, 44px);
            }
        }
    }

    &__icon {
        width: ac(70px, 50px);
        height: ac(70px, 50px);

        img {
            object-fit: contain;
            object-position: left bottom;
        }
    }

    &__title {
        margin-top: ac(20px, 15px);
    }

    &__descr {
        padding-right: 10px;

        width: 100%;
        margin-top: 9px;
        font-family: var(--font-sec);
        font-size: ac(18px, 16px);
        line-height: ac(25px, 22px);
        max-height: calc(10 * ac(25px, 22px));

        p {
            padding-bottom: 1px;
        }

        .simplebar-track.simplebar-vertical {
            width: 3px;
            background-color: rgb(228, 183, 0, .2);
        }

        .simplebar-scrollbar::before {
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-color: var(--cl-yellow);
            opacity: 1;
        }
    }

    &__nav {
        margin-top: 21px;
        margin-left: 0;

        &>*:nth-child(1) {
            margin-left: -116px;
        }
    }

    &__buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        max-width: calc(100% - 240px);
        margin-left: auto;
        margin-right: auto;
        margin-top: 21px;
        gap: ac(20px, 10px);

        .btn {
            margin: 0 !important;
        }

        @media (max-width: 551px) {
          flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            max-width: calc(100% - 120px);
            margin-left: 0;
        }
    }

    &__btn {
        margin: 21px auto 0;
    }

    @mixin max-sm {
        &__btn {
            margin: 21px 0 0;
        }

        &__nav {
            margin-left: auto;
    
            &>*:nth-child(1) {
                margin-left: 0px;
            }
        }
    }

    @media (max-width: 451px) {
      .benefits__wrapper {
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;

      }

        .benefits__buttons {
            order: 3;
            max-width: 100%;

        }
    }
}