.clients {
    margin-top: ac(60px, 30px);
    padding: ac(60px, 30px) 0 ac(35px, 10px);

    @media (max-width: 640px) {
      &:last-child {
          margin-bottom: -30px;
      }
    }

    &.sap-cv-page {
        margin-top: ac(100px, 60px);
    }

    &--country {
        margin-top: ac(100px, 60px);
        padding: 0;

        &::before, &::after {
            display: none;
        }
    }

    &::before, &::after {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 40px);
        height: 2px;
        max-width: 1276px;
        background-color: var(--cl-tealish);
    }

    &::before {
        top: 0;
    }

    &::after {
        bottom: 0;
    }

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
    }

    &__title {
        width: 100%;
    }

    &__slider-box {
        display: flex;
        width: 100%;
        max-width: 1060px;
        margin: ac(20px, 10px) auto 0;

        .swiper-wrapper.swiper-center {
            .clients__slide.swiper-slide-active {
                .clients__logo {
                    filter: grayscale(1);

                    img {
                        transform: scale(1);
                    }

                    &:hover {
                        filter: grayscale(0);

                        img {
                            transform: scale(1.1);
                        }
                    }
                }
            }
        }
    }

    &__slide {
        display: flex;

        &.swiper-slide-active {
            .clients__logo {
                filter: grayscale(0);

                img {
                    transform: scale(1.1);
                }
            }
        }
    }

    &__logo {
        margin: auto;
        position: relative;
        padding: 25px;
        filter: grayscale(1);
        transition: .3s ease;
        height: ac(126px, 80px, 320, 1440);
     
        img {
            object-fit: contain;
            object-position: center;
            transition: .3s ease;
        }

        &:hover {
            filter: grayscale(0);

            img {
                transform: scale(1.1);
            }
        }
    }

    @mixin max-xs {
        &__logo {
            padding: 20px 10px;
        }
    }
}