.looking {
    margin-top: ac(110px, 60px);

    &__title {
        color: var(--cl-white);
    }

    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            height: calc(100% - ac(120px, 80px));
            transform: translateY(-50%);
            background: rgba(255, 255, 255, 0.30);
            backdrop-filter: blur(15px);
        }
    }

    &__wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: ac(120px, 69px) 0 ac(120px, 78px);
    }

    &__title {
        width: calc(100% - 130px);
        max-width: 628px;
    }

    &__nav {
        margin-bottom: ac(-20px, 0px);
    }

    &__slider-box {
        margin-top: ac(40px, 32px);
        width: 100%;
        display: flex;
    }

    &__card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: 100%;
        padding: ac(30px, 28px) ac(28px, 18px) ac(9px, 18px) ac(28px, 26px);
        background-color: var(--cl-white);
        border: 2px solid var(--cl-white);
        transition: .3s ease;

        .arrow-btn {
            margin-right: ac(-20px, 0px);
        }

        &:hover {
            background-color: var(--cl-tealish);

            .arrow-btn {
                transform: rotate(45deg);
                background-color: var(--cl-white);
            }

            .looking__info {
                color: var(--cl-white);
            }

            .looking__sep {
                background-color: var(--cl-white);
            }
        }
    }

    &__name {
        margin-bottom: 10px;
    }

    &__sep, &__info-box {
        margin-bottom: 15px;
    }

    &__name {
        font-size: ac(25px, 22px);
        font-weight: 600;
        line-height: 156%;
        color: var(--cl-yellow);
        word-break: break-word;
        overflow-wrap: break-word;

        @mixin max-sm {
            font-weight: 700;
            line-height: 154.5%;
        }
    }

    &__sep {
        width: 100%;
        height: 1px;
        background-color: var(--cl-dark-blue);
        transition: .3s ease;
    }

    &__info-box {
        display: grid;
        grid-template-columns: -webkit-max-content auto;
        grid-template-columns: max-content auto;
        row-gap: ac(7px, 10px);
    }

    &__info {
        font-family: var(--font-sec);
        transition: .3s ease;
        line-height: 119%;

        &:nth-child(odd) {
            margin-right: 32px;
        }
    }

    &__btn-box {
        margin-top: ac(50px, 40px);
    }

    &__btn {
        .btn__text {
            color: var(--cl-white);
        }
    }

    &__link-box {
        margin-top: ac(40px, 24px);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        text-transform: uppercase;
        grid-column-gap: ac(24px, 20px);
        grid-row-gap: ac(16px, 12px);
        width: 100%;
    }

    &__link {
        font-size: ac(20px, 16px);
        color: var(--cl-dark-blue);
        font-weight: 600;
        line-height: normal;
        text-align: center;

        transition: .3s;

        &:hover {
            color: var(--cl-yellow);
        }

        &.yellow-color {
            color: var(--cl-yellow);

            &:hover {
                color: var(--cl-blue);
            }
        }
    }

    &--talent,
    &--jobs {
        margin-top: 0;
        background-color: var(--cl-dark-blue);

        .looking__title {
            color: var(--cl-tealish);
        }

        .looking__head {
            width: 100%;
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            gap: 20px;
        }

        .looking__title {
            max-width: unset;

            width: 100%;
            font-size: ac(32px, 25px);
            line-height: 140%;
            font-weight: 600;

            @mixin max-sm {
                line-height: 160%;
            }
        }

        .looking__subtitle {
            margin-top: ac(15px, 5px);
            a {
                color: var(--cl-yellow);
                transition: .3s ease;

                &:hover {
                    opacity: .5;
                }
            }
        }

        .looking {
            &__wrapper {
                width: 100%;
                padding: ac(60px, 30px) 0;
                align-items: initial;
            }

            &__card-box {
                width: 100%;
                display: grid;
                grid-template-columns: repeat(3, minmax(0, 1fr));
                grid-column-gap: ac(20px, 10px);
                grid-row-gap: ac(20px, 10px);
                margin-top: ac(30px, 15px);

                .job-results-title {
                    color: var(--cl-white);
                    margin: 0 auto;
                }

                @media (max-width: 1023px) {
                    grid-template-columns: repeat(2, minmax(0, 1fr));
                }

                @media (max-width: 767px) {
                    grid-template-columns: repeat(1, minmax(0, 1fr));
                }
            }

            &__card {
                height: auto;
            }

            &__sort {
                display: flex;
                align-items: center;
                margin-left: auto;

                .choices {
                    &::before {
                        top: 9px;
                        right: 0px;
                        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='12' fill='none'%3E%3Cpath stroke='%23fff' stroke-linejoin='bevel' stroke-width='3' d='m2 2 8.5 8.5L19 2'/%3E%3C/svg%3E") center / contain no-repeat;
                    }

                    .choices__inner {
                        background-color: transparent;
                        min-height: unset;
                        border-radius: 0 !important;
                        padding: 0 18px !important;
                    }

                    .choices__list {
                        &--single {
                            .choices__item {
                                font-size: ac(18px, 16px);
                                line-height: 28px;
                                color: var(--cl-white);
                            }
                        }

                        &--dropdown {
                            right: 0;
                            min-width: 150px;
                            border-radius: 0 !important;
                            .choices__item {
                                padding-right: 5px!important;
                            }
                        }
                    }
                }
            }

            &__label {
                font-size: ac(18px, 16px);
                line-height: ac(20px, 18px);
                margin-right: ac(12px, 0px);
                color: var(--cl-white);
            }
        }
    }

    &--jobs {
        .looking__title, .looking__subtitle {
            color: var(--cl-white);
        }
        .looking__link-box .looking__link {
            &:hover {
                color: var(--cl-white);
            }
        }
    }

    &--talent {
        background-color: var(--cl-light-blue);

        .pagination {
            &__num {
                color: var(--cl-blue);
            }
        }

        .looking {
            &__sort .choices:before {
                background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='12' fill='none'%3E%3Cpath stroke='%2304143A' stroke-linejoin='bevel' stroke-width='3' d='m2 2 8.5 8.5L19 2'/%3E%3C/svg%3E") center / contain no-repeat;
            }
            &__label, &__sort .choices .choices__list--single .choices__item {
                color: var(--cl-blue);
            }

            &__sep {
                background-color: var(--cl-white);
            }

            &__info {
                color: var(--cl-white);
            }

            &__card {
                min-height: ac(360px, 280px);
                background-color: var(--cl-blue);
                border-color: var(--cl-blue);
                padding: ac(30px, 28px) ac(28px, 18px) ac(35px, 18px) ac(28px, 26px);

                &:hover {
                    background-color: var(--cl-white);

                    .arrow-btn {
                        transform: rotate(45deg);
                        background-color: var(--cl-blue);
                    }

                    .looking__info {
                        color: var(--cl-blue);
                    }

                    .looking__sep {
                        background-color: var(--cl-blue);
                    }
                }
            }
        }
    }

    &--country-main {
        margin-top: ac(100px, 30px);

        .looking__bg {
            background-color: var(--cl-dark-blue);

            &::before, img {
                display: none;
            }
        }

        .looking__wrapper {
            padding: ac(100px, 30px) 0;
        }

        .looking__subtitle {
            margin-top: ac(15px, 5px);
            color: var(--cl-white);

            a {
                color: var(--cl-yellow);
                transition: .3s ease;

                &:hover {
                    opacity: .5;
                }
            }
        }

        .looking__link-box .looking__link {
            &:hover {
                color: var(--cl-white);
            }
        } 
    }

    &--dark {
        margin-top: ac(60px, 30px);
    }

    &--country-dark {
        margin-top: ac(100px, 30px);

        .looking__bg {
            background-color: var(--cl-light-blue);

            &::before, img {
                display: none;
            }
        }

        .looking__btn {
            .btn__text {
                color: var(--cl-tealish);
            }

            &:hover {
                .btn__text {
                    color: var(--cl-white);
                }
            }
        }

        .looking__wrapper {
            padding: ac(100px, 30px) 0;
        }

        .looking__title {
            width: 100%;
        }

        .looking__subtitle {
            max-width: ac(800px, 500px);
            width: 100%;
            margin-top: ac(15px, 5px);

            a {
                color: var(--cl-yellow);
                transition: .3s ease;

                &:hover {
                    opacity: .5;
                }
            }
        }
    }

    &--dark, &--country-dark {
        .looking {
            &__bg {
                width: 130%;
                left: -27%;
                
                img {
                    object-position: 60% top;
                }
            }

            &__nav {
                margin-bottom: ac(-9px, 0px);
            }

            &__title {
                color: var(--cl-tealish);
            }

            &__card {
                background-color: var(--cl-tealish);
                border-color: var(--cl-tealish);
                padding: ac(30px, 28px) ac(28px, 18px) ac(35px, 18px) ac(28px, 26px);

                .arrow-btn {
                    transform: translateY(ac(28px, 0px));
                }

                &:hover {
                    background-color: var(--cl-white);

                    .arrow-btn {
                        transform: translateY(ac(28px, 0px)) rotate(45deg);
                        background-color: var(--cl-tealish);
                    }

                    .looking__info {
                        color: var(--cl-tealish);
                    }

                    .looking__sep {
                        background-color: var(--cl-tealish);
                    }
                }
            }

            &__sep {
                background-color: var(--cl-white);
            }

            &__info {
                color: var(--cl-white);
            }
        }
    }

    @mixin md {
        &--talent,
        &--jobs {
            .looking__name {
                font-size: ac(25px, 18px);
            }
            .looking__title-box {
                flex-shrink: 0;
                max-width: ac(950px, 450px);
            }
            .looking__card {
                padding: ac(30px, 20px) ac(28px, 15px) ac(9px, 15px) ac(28px, 20px);
            }

            .looking__info {
                font-size: ac(16px, 14px);

                &:nth-child(odd) {
                    margin-right: ac(32px, 15px);
                }
            }
        }
    }

    @mixin max-md {
        &--talent,
        &--jobs {
            .looking__card-box {
                grid-template-columns: 1fr;
            }

            .looking__head {
                flex-direction: column;
            }
        }

        &__wrapper {
            width: calc(100% - 16px);
        }
        
        &__title {
            width: 100%;
            max-width: unset;
        }

        &__btn {
            transform: translateX(9px);
        }

        .slider-nav {
            margin-top: 8px;
        }
    }

    @mixin max-xs {
        &__card {
            padding: 10px;
        }
    }
}