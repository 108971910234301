.specialist {
    margin-top: ac(98px, 30px);
    z-index: 1;

    padding: ac(68px, 30px) 0 ac(59px, 30px);

    &::after, &::before {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 40px);
        height: 2px;
        max-width: 1276px;
        background-color: var(--cl-tealish);
    }

    &::before {
        top: 0;
    }

    &::after {
        bottom: 0;
    }

    &__title-box {
        display: flex;
        width: 100%;
    }

    &__title {
        flex-shrink: 0;
        max-width: ac(520px, 295px, 768, 1440);
        margin-right: 20px;
    }

    &__descr {
        margin-top: ac(-5px, 0px);
        font-family: var(--font-sec);
        font-size: ac(22px, 18px);
        line-height: ac(32px, 24px);
    }

    &__slider-box {
        margin-top: ac(60px, 30px);
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }

    &__nav {
        margin-bottom: 15px;
    }

    &__card {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
    }

    &__value {
        margin-bottom: ac(31px, 15px);
        position: relative;
        font-size: ac(40px, 26px);
        line-height: normal;
        font-weight: 600;
        color: var(--cl-dark-blue);
        overflow: hidden;
        padding: 11px 25px 7px 26px;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: -10px;
            width: 100%;
            height: 100%;
            background-color: var(--cl-yellow);
            transform: skew(10deg);
            z-index: -1;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 5px;
            height: 100%;
            background-color: var(--cl-white);
            z-index: -1;
        }

        @mixin max-sm {
            font-weight: 700;
        }
    }

    &__text {
        margin-bottom: ac(29px, 15px);
        font-size: ac(18px, 16px);
        font-weight: 600;
        line-height: 120%;
        text-align: center;
        font-family: var(--font-sec);
    }

    &__btn {
        margin-top: auto;
    }

    &--country {
        padding: ac(60px, 30px) 0;

        .specialist {
            &__wrapper {
                display: flex;
                align-items: center;
            }

            &__title-box {
                max-width: ac(791px, 400px);
                width: 100%;
                flex-shrink: 0;
                border-right: 2px solid var(--cl-tealish);
                flex-direction: column;
                align-items: flex-start;
                
                /* margin-left: ac(108px, 0px); */
                padding-right: ac(53px, 15px);
                margin-right: ac(53px, 15px);
            }

            &__title {
                max-width: unset;
                margin-bottom: ac(26px, 15px);
                margin-right: 0;
            }

            &__descr {
                margin-bottom: ac(30px, 20px);
                line-height: ac(32px, 26px);
            }


            &__card {
                .btn__text {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    padding: 0 ac(10px, 5px);
                    margin-right: 0;

                    text-align: center;
                    line-height: ac(18px, 16px, 320, 1440);
                    font-size: ac(16px, 14px, 320, 1440);
                    white-space: nowrap;
                }

                .arrow-btn {
                    margin-right: 0;
                }

                .specialist__btn {
                    max-width: 350px;
                    width: calc(100% + 2px);
                }
            }

            &__value {
                font-size: ac(60px, 36px);
                padding: ac(20px, 14px) ac(45px, 30px) ac(16px, 10px) ac(45px, 30px);
            }

            &__text {
                font-size: ac(22px, 18px);
            }
        }
    }

    @mixin max-md {
        &__title-box {
            flex-direction: column;
        }

        &__title {
            max-width: unset;
            margin-right: 0;
        }

        &__descr {
            margin-top: 20px;
        }

        &--country {
            .specialist__wrapper {
                flex-direction: column;
            }

            .specialist__title-box {
                max-width: unset;
                border-right: 0;
                border-bottom: 2px solid var(--cl-tealish);
                padding-right: 0;
                margin-right: 0;
                padding-bottom: 25px;
            }

            .specialist__card {
                margin-top: 25px;
            }
        }
    }

    @mixin max-sm {
        &__card {
            flex-direction: row;
            flex-wrap: wrap;
            margin-bottom: -15px;
        }

        &__btn {
            margin-left: auto;
        }

        &__value {
            width: 100%;
        }

        &__text {
            max-width: calc(100% - 170px);
            margin-bottom: 0;
            text-align: left;
            margin-right: 10px;
        }

        &__text, &__btn {
            margin-bottom: 15px;
        }

        &--country {
            .specialist__card {
                justify-content: center;

                .specialist__btn {
                    margin-left: 0;
                }

                .specialist__text {
                    max-width: unset;
                    text-align: center;
                }
            }
        }
    }

    @mixin max-xs {
        &--country {
            .specialist__card {
                .btn__text {
                    font-size: 14px;
                }
             
                .specialist__btn {
                    max-width: unset;
                    width: auto;
                }
            }
        }
    }
}