.faq {
    position: relative;
	margin-top: ac(100px, 60px);

    &::before, &::after {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 40px);
        height: 2px;
        max-width: 1276px;
        background-color: var(--cl-tealish);
    }
    
    &::before {
        top: 0;
    }

    &::after {
        bottom: 0;
    }

    &__title {
        margin-bottom: ac(30px, 15px);
    }

    &__wrapper {
        padding: ac(60px, 30px) ac(108px, 0px);
        background: var(--cl-light-blue);
    }

    &__accardion {
        background-color: var(--cl-white);
        box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.07);
		transition: .3s ease;

		&:not(:last-child) {
			margin-bottom: 10px;
		}

    }

    &__accardion-head {
		display: flex;
		justify-content: space-between;
		align-items: center;
		cursor: pointer;
		padding: ac(24px, 15px) ac(21px, 15px) ac(22px, 15px) ac(19px, 15px);
		transition: .3s ease;

		&:hover {
			&>span {
				color: var(--cl-yellow);
			}

            &>div {
                &::before, &::after {
                    background-color: var(--cl-yellow);
                }
            }
		}

		&>span {
			font-size: ac(25px, 16px);
			line-height: ac(34px, 20px);
			font-weight: 600;
			transition: .3s ease;

			&:first-child {
				margin-right: 20px;
			}
		}

        &>div {
            flex-shrink: 0;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20px;
            height: 20px;

            &::before, &::after {
                content: '';
                position: absolute;
                background-color: var(--cl-tealish);
                border-radius: 15px;
                transition: .3s ease;
            }

            &::before {
                width: 100%;
                height: 4px;
            }

            &::after {
                width: 4px;
                height: 100%;
            }
        }

		&.open {
            &>div {
                &::after {
                    height: 0;
                }
            }
		}
    }

    &__accardion-content {
        max-height: 0;
        overflow: hidden;
        padding: 0 !important;
        transition: max-height 0.5s ease;
	
		&>div {
            padding: 0px ac(18px, 15px) ac(20px, 15px);
		}

		&.open {
			max-height: 1000px;
		}
    }


    @mixin max-lg {
        background: var(--cl-light-blue);
    }
}