.page-404 {
    padding: 80px 0;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: var(--cl-dark-blue);
    overflow: hidden;
       
    &__title {
        margin-top: 15vh;
        position: relative;
        font-size: ac(300px, 80px, 320, 1440);
        line-height: ac(334px, 100px, 320, 1440);
        color: var(--cl-white);
        z-index: 5;
    }

    &__subtitle {
        position: relative;
        font-weight: 600;
        font-size: ac(40px, 20px, 320, 1440);
        line-height: ac(55px, 35px, 320, 1440);
        color: var(--cl-white);
        z-index: 3;
    }

    &__btn {
        position: relative;
        margin-top: ac(40px, 20px, 320, 1440);
        z-index: 3;
    }

    &__decor {
        position: absolute;
        bottom: 0;
        left: -1%;
        width: 102%;
        z-index: 2;
        transition: transform .3s ease;

        img {
            object-position: center top;
        }

        &--front {
            height: 57vh;
            animation: ticker 10s linear infinite;
        }

        &--back {
            height: 65vh;
            z-index: 1;
            animation: ticker-alt 10s ease infinite;
        }

        &--stars {
            top: 50%;
            left: 50%;
            width: ac(1148px, 300px, 320, 1440);
            height: ac(900px, 300px, 320, 1440);
            transform: translate(calc(-50% + ac(100px, 20px, 320, 1440)), calc(-50% - 0px));
            z-index: 0;

            svg {
                width: 100%;
                height: 100%;
                object-fit: contain;

                path {
                    opacity: .1;

                    &.path-1 {
                        animation: zoomIn 1s 1.5s 1 both ease, heartScale 4.5s 4s infinite ease;
                    }
        
                    &.path-2 {
                        animation: zoomIn .5s 1.25s 1 both ease, heartScale 4s 2.75s infinite ease;
                    }
        
                    &.path-3 {
                        animation: zoomIn 1s 1s 1 both ease, heartScale 3.5s 2.5s infinite ease;
                    }

                    &.path-4 {
                        animation: zoomIn 1s .75s 1 both ease, heartScale 3s 2.25s infinite ease;
                    }

                    &.path-5 {
                        animation: zoomIn 1s .5s 1 both ease, heartScale 2.5s 2s infinite ease;
                    }
                }
            }
            @keyframes zoomIn {
                0% {
                    transform: scale(0);
                }
    
                100% {
                    transform: scale(1);
                }
            }
    
            @keyframes heartScale {
                0% {
                    opacity: 1;
                }
    
                25%,75% {
                    opacity: .1;
                }
    
                50% {
                    opacity: 0.8;
                    
                }
    
                100% {
                    opacity: 1;
                }
            }
        }

        @keyframes ticker-alt {
            0%, 100% {
                transform: translateX(-1%);
            }
            50% {
                transform: translateX(1%);
            }
        }
        @keyframes ticker {
            0%, 100% {
                transform: translateX(1%);
            }
            50% {
                transform: translateX(-1%);
            }
        }
    }
}