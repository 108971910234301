.fp-watermark {
  display: none;
}

html {
    scrollbar-width: thin;
    scrollbar-color: var(--cl-yellow) var(--cl-white);
    scroll-padding-top: 100px;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: var(--cl-white);
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--cl-yellow);
    border-radius: 0;
  }
}

body {
  min-width: 320px;
  background: var(--cl-white);
  color: var(--cl-tealish);
  font-family: var(--font-main);
  font-size: 16px;
  overflow-x: hidden;
}

.swiper {
  width: 100% !important;
}

.swiper-wrapper {
  left: 0 !important;

    &.swiper-center {
        justify-content: center;
    }
}

#site {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: calc(100 * var(--vh));
}

.main {
  flex-grow: 1;
}

.cont {
  width: 100%;
  max-width: 1316px;
  margin: 0 auto;
  padding: 0 20px;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

section {
  position: relative;
}

#bold-credits {
  width: 148px;
  transition: ease-in-out 0.25s;

  &::before,
  &::after {
    display: none;
  }

  &:hover {
    transform: scale(1.1);
  }

  @mixin max-sm {
    width: 159px;
  }
}

#site.cookie-terms-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;

  .main {
    flex-grow: 1;
    padding: 0;
  }
}

.cookie-terms-section {
  padding: ac(250px, 140px) 0 ac(150px, 50px);
  p {
    padding: 0;
    margin-bottom: 10px;
  }
  ul,
  ol {
    padding-left: 20px;
    li {
      p {
        margin-bottom: 0;
      }
    }
  }

  ul {
    list-style: circle;
  }

  ol {
    list-style: decimal;
  }

  a {
    display: inline;
    text-decoration: underline;
    transition: 0.2s;

    &:hover {
      opacity: 0.7;
    }
  }
}

.pagination {
    margin: ac(80px, 30px) auto 0;
	display: flex;
	align-items: center;

	&__num {
		margin-right: ac(25px, 20px);
		font-weight: 600;
		font-size: ac(25px, 20px);
		line-height: ac(47px, 36px);
		color: var(--cl-white);
		opacity: 0.5;
		cursor: pointer;
		user-select: none;
		transition: .3s ease;

		&:last-of-type {
			margin-right: 0;
		}

		&:hover {
			opacity: 1;
		}

		&.is-active, &--dots {
			opacity: 1;
			pointer-events: none;
		}
		
	}

	&__btn {
		display: flex;
		align-items: center;
		justify-content: center;
		width: ac(47px, 36px);
		height: ac(47px, 36px);
		background-color: var(--cl-white);
		border-radius: 100%;
		cursor: pointer;
		border: 2px solid var(--cl-white);
		transition: .3s ease;
		
		&.is-disabled {
			opacity: .5;
			pointer-events: none;			
		}

		&--prev {
			margin-right: ac(30px, 25px);

			.pagination__icon {
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19' height='21' fill='none'%3E%3Cpath stroke='%23061654' stroke-linejoin='bevel' stroke-width='3' d='M10.938 19.486 1.91 10.458l9.028-9.027m7.797 9.483H2.32'/%3E%3C/svg%3E");
			}
		}

		&--next {
			margin-left: ac(30px, 25px);

			.pagination__icon {
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19' height='21' fill='none'%3E%3Cpath stroke='%23061654' stroke-linejoin='bevel' stroke-width='3' d='m8.063 1.514 9.028 9.027-9.028 9.028M.266 10.085H16.68'/%3E%3C/svg%3E");
			}
		}

		&:hover {
			background-color: var(--cl-dark-blue);

			.pagination__icon {
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19' height='21' fill='none'%3E%3Cpath stroke='%23fff' stroke-linejoin='bevel' stroke-width='3' d='M10.938 19.486 1.91 10.458l9.028-9.027m7.797 9.483H2.32'/%3E%3C/svg%3E");
			}

			&.pagination__btn--next {
				.pagination__icon {
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19' height='21' fill='none'%3E%3Cpath stroke='%23fff' stroke-linejoin='bevel' stroke-width='3' d='m8.063 1.514 9.028 9.027-9.028 9.028M.266 10.085H16.68'/%3E%3C/svg%3E");
				}
			}
		}
	}

	&__icon {
		width: 19px;
		height: 21px;
		background-position: center;
		background-repeat: repeat;
		background-size: contain;
		transition: .3s ease;
	}
}

.single-page-404 {
  min-height: 100vh;
  padding: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1,
  p {
    margin-bottom: 15px;
    padding: 0;
  }

  a {
    display: inline-block;
    margin-top: 20px;
  }
}

.grecaptcha-badge {
    z-index: 99;
    opacity: 0 !important;
    visibility: hidden !important;
}

@mixin lg {
  .hide-lg {
    display: none;
  }
}

.text-vertical-animation {
  position: relative;
  .text-vertical-first, .text-vertical-second {
    color: var(--cl-dark-blue);
    text-shadow: 1px 1px 0px var(--cl-white), -1px -1px 0px var(--cl-white);
  }

  .text-vertical-first {
    transition: .3s;
    opacity: 0;

    &.is-start {
      opacity: 1;
    }
  }

  .text-vertical-second {
    transition: .3s;
    opacity: 1;
    transition-delay: 1.3s;

    &.is-start {
      opacity: 0;
    }
  }
}
.text-vertical-anim-text {
  /*background: var(--cl-yellow);*/
  font-size: ac(28px, 18px);
  font-family: var(--font-main);
  line-height: 145%;
  color: var(--cl-yellow);
  position: relative;
  transition: transform 0.1s ease, margin-left 3s;
  margin-left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  z-index: 10;
  white-space: nowrap;
  width: min-content;
  margin-top: -4px;

  /*&:after {
    content: '.';
    font: inherit;
    transition: .3s;
    transition-delay: 3s;
    position: absolute;
    bottom: 0;
    right: -6px;
    color: var(--cl-white);
    opacity: 1;
  }*/

  span {
    transition: 1.5s ease;
    width: 0!important;
    opacity: 0;
    overflow: hidden;
    display: flex;
  }

  &.is-start {
    span {
      width: 100% !important;
      opacity: 0.7;
    }

    /*&:after {
      opacity: 1;
    }*/
  }

  @media (max-width: 425px) {
    font-size: 15px;
    margin-top: -2px;
  }

  @media (max-width: 360px) {
    font-size: 13px;
  }
}

.section-navy-container {
  background-color: var(--cl-dark-blue);
  padding-top: ac(71px, 68px);
  width: 100%;
}

.text-line-animation-container {
  width: min-content;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: auto;
  margin-right: auto;
}

.text-line-path {
  position: absolute;
  opacity: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
  svg {
    width: 100%;
    height: 100%;
  }
}

.text-line-animation {
  display: flex;
  white-space: nowrap;
  width: min-content;
}

.text-line-word-el {

}

.text-line-animation-container {
  opacity: 0;
  transition: opacity .3s;

  @media (max-width: 1023px) {
    display: none;
  }
}

.text-line-word-text {
  background: var(--cl-yellow);
  font-size: ac(28px, 18px);
  font-family: var(--font-main);
  line-height: 145%;
  color: var(--cl-dark-blue);
  position: relative;
  background: var(--cl-yellow);
  transition: transform 0.1s ease, margin-left 3s;
  margin-left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  z-index: 10;

  &:after {
    content: '.';
    font: inherit;
    transition: .3s;
    transition-delay: 3s;
    position: absolute;
    bottom: 0;
    right: -6px;
    color: var(--cl-white);
    opacity: 0;
  }

  span {
    transition: 1.5s ease;
    width: 0!important;
    opacity: 0;
    overflow: hidden;
    display: flex;
  }

  &.is-start {
    span {
      width: 100% !important;
      opacity: 1;
    }

    &:after {
      opacity: 1;
    }

    margin-left: ac(45px, 65px, 1440, 1024);
  }


}

.text-line-first {
  display: flex;
  overflow: hidden;
  transition: 1.5s ease;
  width: ac(72px, 77px, 1440, 1024) !important;
  opacity: 0;

  &.is-start {
    width: 0 !important;
  }
}

.text-line-second {
  display: flex;
  overflow: hidden;
  transition: 1.5s ease;
  width: 0 !important;
  opacity: 0;
  &.is-start {
    width: ac(72px, 77px, 1440, 1024) !important;
  }
}

.text-line-dot {
  transition: 0.5s;
  transition-delay: 1.5s;
  opacity: 1;

  &.is-start {
    opacity: 0;
  }
}

/*@keyframes textLineFirstAnim {
  0% {
    opacity: 1;
  }
  100% {
    width: 0;
    opacity: 0;
  }
}*/

.typed-fade-out {
  opacity: 0;
  transition: opacity 0.5s ease;
}

.text-typed-animation {
  opacity: 0;
  transition: opacity .1s ease;

  @media (min-width: 426px) {
    display: none;
  }
}

.text-cursor {
  position: relative;
  z-index: 1;

  &.after-opacity {
    background-color: transparent !important;
  }
}

.text-cursor::before {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: ac(4px, 3px);
  background-color: var(--cl-yellow);
  animation: blink 0.7s steps(1) infinite;
  visibility: hidden;
}

.text-cursor.typing::before {
  visibility: visible;
}

@keyframes blink {
  0%, 50% {
    opacity: 1;
  }
  50.01%, 100% {
    opacity: 0;
  }
}

.border-line {
  position: relative;
  height: 2px;
  width: calc(100% - 40px);
  display: block;
  max-width: 1276px;
  background-color: var(--cl-tealish);
  margin-left: auto;
  margin-right: auto;
}

.text-section {
  margin-top: ac(100px, 60px);
  padding-top: ac(120px, 40px);
  padding-bottom: ac(120px, 40px);
  background-color: var(--cl-dark-blue);

  @media (max-width: 1024px) {
    display: none;

    + .border-line {
      display: none;
    }
  }

  + .border-line {
    margin-top: ac(60px, 30px);
    margin-bottom: ac(-40px, -30px);
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
  }

  &__text {
    color: var(--cl-white);
    font-size: ac(28px, 18px);
    font-family: var(--font-main);
    line-height: 145%;

    &.text-typed-animation {
      position: relative;
      z-index: 10;
      .text-typed-first, .text-typed-second {
        color: var(--cl-dark-blue);
        position: relative;
        background: var(--cl-yellow);
        transition: background-color .1s;

        /*&:after {
          position: absolute;
          z-index: -1;
          top: 0;
          bottom: 0;
          left: -2px;
          right: -2px;
          background: var(--cl-yellow);
          content: "";
        }*/
      }
    }

    @media (max-width: 425px) {
      font-size: 15px;
      width: 100%;
    }

    @media (max-width: 360px) {
      font-size: 13px;
    }

    &:not(:last-child) {
      margin-bottom: ac(40px, 26px);
    }
  }
}

/* new pagination */
#pagination-block {
  b + .pagination__num {
    margin-left: ac(25px, 20px);
  }
  &.bg-white {
    .pagination__num {
      color: var(--cl-dark-blue);
    }
    .pagination__btn {
      border: 2px solid var(--cl-dark-blue);
    }
  }
}