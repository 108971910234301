.testimonials {
    margin-top: ac(160px, 40px);
    background-color: var(--cl-tealish);
    overflow: hidden;

    &__wrapper {
        position: relative;
        display: flex;
        align-items: flex-start;
        padding: 45px 0 45px;
        z-index: 1;
    }

    &__img, &__decor {
        position: absolute;
        z-index: -1;

        img {
            object-fit: contain;

            @media (max-width: 551px) {
              object-position: bottom;

            }
        }

        &.with-cover {
            img {
                object-fit: cover;
            }
        }
    }

    &__img {
        bottom: ac(-103px, -110px);
        left: ac(63px, 0px);
        width: ac(465px, 360px);
        height: ac(600px, 490px);

        img {
            transform: scaleX(-1);
        }
    }

    &__decor {
        left: ac(-250px, -220px, 768, 1440);
        bottom: ac(-150px, 40px, 768, 1440);
        width: ac(760px, 500px, 768, 1440);
    }

    &__slider-box {
        display: flex;
        flex-wrap: wrap;
        margin-left: auto;
        margin-right: ac(-15px, 0px);
        width: 100%;
        max-width: ac(643px, 440px, 768, 1440);
    }

    &__title, &__descr, &__name, &__pos {
        color: var(--cl-white);
    }

    &__title {
        font-size: ac(40px, 24px);

        @media (max-width: 360px) {
            font-size: 20px;
        }
    }

    &__slider, &__info {
        margin-top: ac(40px, 25px);
    }

    &__bottom {
        margin-top: ac(38px, 25px);
    }

    &__descr {
        font-size: ac(22px, 18px);
        font-family: var(--font-sec);
        line-height: 146%;
        max-height: 198px;
        padding-right: 5px;

        p {
            padding-bottom: 1px;
        }

        .simplebar-content {
            padding-right: ac(15px, 10px) !important;
        }
        
        .simplebar-track.simplebar-vertical {
            width: 3px;
            background-color: rgba(255, 255, 255, 0.2);
        }

        .simplebar-scrollbar::before {
            background-color: var(--cl-yellow);
            opacity: 1;
            top: 0 !important;
            left: 0 !important;
            bottom: 0 !important;
            right: 0 !important;
            border-radius: 0;
        }

        @mixin max-sm {
            line-height: 155%;
        }
    }

    &__info {
       display: flex;
    }

    &__photo {
        flex-shrink: 0;
        margin-right: 15px;
        width: 50px;
        height: 50px;
        border-radius: 100%;
        overflow: hidden;
    }

    &__name, &__pos {
        font-size: ac(16px, 14px);
        font-weight: 600;
        line-height: 150%;

        @mixin max-sm {
            font-weight: 700;
            line-height: 171%;
        }
    }

    &__bottom {
        display: flex;
        width: 100%;
    }

    &__nav {
        margin-left: 0;
    }

    &__btn {
        @media (max-width: 400px) {
            .btn__text {
                font-size: ac(15px, 13px, 320, 400);
                margin-right: ac(10px, 7px, 320, 400);
            }
        }
    }

    .slider-nav__btn--next {
        margin-right: ac(50px, 10px, 320, 1440);
    }

    &--why {
        margin-top: ac(100px, 30px);

        .testimonials__img {
            bottom: ac(-275px, -130px);
            left: ac(-25px, -30px);
            width: ac(625px, 300px);
            height: ac(760px, 470px);
            z-index: 1;

            img {
                transform: scaleX(1);
            }

            &.with-cover {
                @media (min-width: 640px) {
                    bottom: -50px;

                }
            }
        }
    }

    &--country {
        margin-top: ac(100px, 60px);

        .testimonials__wrapper {
            padding: 60px 0 82px;
        }

        .testimonials__img {
            bottom: -2px;
            left: ac(-165px, -120px);
            width: ac(728px, 440px);
            height: ac(586px, 390px);
            z-index: 1;

            img {
                object-position: right bottom;
                transform: scaleX(1);
            }
        }
    }

    @mixin md {
        &__btn-box {
            display: none;
        }
    }

    @mixin max-md {
        &__btn-box {
            margin-top: 20px;
            margin-bottom: -10px;
            margin-right: -15px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            .btn {
                margin-right: 15px;
                margin-bottom: 10px;
            }
        }

        &::before {
            display: none;
        }

        &--country .testimonials__wrapper,
        &__wrapper {
            padding-top: 40px;
            padding-bottom: 432px;
        }

        &__slider-box {
            max-width: unset;
        }

        &--why .testimonials__img,
        &__img {
            width: 276px;
            height: 376px;
            left: 53%;
            transform: translateX(-50%);
            bottom: 0;
        }

        &--country .testimonials__img {
            width: 476px;
            height: ac(385px, 340px, 320, 768);
            left: 40%;
            transform: translateX(-50%);
        }

        &__decor {
            width: 452px;
            left: calc(50% - 280px);
            bottom: -20px;
        }
    }

    @mixin max-xs {
        &__btn-box {
            flex-wrap: nowrap;
            margin-right: -5px;

            .btn {
                margin-right: 5px;
            }

            .btn__text {
                padding-top: 2px;
                font-size: 14px;
                margin-right: 7px;
            }
        }
    }
}