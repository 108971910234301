.services {
    margin-top: ac(100px, 40px);

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;

        &>.btn {
            margin-left: auto;
        }
    }

    &__card-box {
        margin-top: ac(60px, 25px);
        display: flex;
        flex-wrap: wrap;
        margin-right: ac(-20px, -15px);
        margin-bottom: ac(-20px, -15px);
    }

    &__card {
        padding: ac(39px, 20px) ac(40px, 15px) ac(27px, 20px);
        width: calc(100% / 2 - ac(20px, 15px));
        display: flex;
        flex-wrap: wrap;
        background-color: var(--cl-dark-blue);
        margin-right: ac(20px, 15px);
        margin-bottom: ac(20px, 15px);
        min-height: 308px;

        &--contr {
            border-top: 10px solid #159898;
            border-bottom: 10px solid #159898;

            .services__btn {
                &>span {
                    background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none'%3E%3Cpath stroke='%23159898' stroke-linejoin='bevel' stroke-width='3' d='M1 2.384h12.767V15.15m-12.22-1.192L13.156 2.352'/%3E%3C/svg%3E") 50% /contain no-repeat;
                }
            }
        }
        &--contin {
            border-top: 10px solid #EA7159;
            border-bottom: 10px solid #EA7159;

            .services__btn {
                &>span {
                    background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none'%3E%3Cpath stroke='%23EA7159' stroke-linejoin='bevel' stroke-width='3' d='M1 2.384h12.767V15.15m-12.22-1.192L13.156 2.352'/%3E%3C/svg%3E") 50% /contain no-repeat;
                }
            }
        }
        &--prem {
            border-top: 10px solid #B83C64;
            border-bottom: 10px solid #B83C64;

            .services__btn {
                &>span {
                    background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none'%3E%3Cpath stroke='%23B83C64' stroke-linejoin='bevel' stroke-width='3' d='M1 2.384h12.767V15.15m-12.22-1.192L13.156 2.352'/%3E%3C/svg%3E") 50% /contain no-repeat;
                }
            }
        }
        &--job {
            border-top: 10px solid #8682FE;
            border-bottom: 10px solid #8682FE;

            .services__btn {
                &>span {
                    background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none'%3E%3Cpath stroke='%238682FE' stroke-linejoin='bevel' stroke-width='3' d='M1 2.384h12.767V15.15m-12.22-1.192L13.156 2.352'/%3E%3C/svg%3E") 50% /contain no-repeat;
                }
            }
        }
    }

    &__title {
        margin-bottom: 5px;
        font-size: ac(30px, 22px);
        line-height: ac(42px, 30px);
        font-weight: 600;
        color: var(--cl-yellow);
    }

    &__descr {
        color: var(--cl-white);
        font-weight: 400;
        font-size: ac(22px, 18px);
        line-height: ac(32px, 24px);
        font-family: var(--font-sec);
        max-height: calc(ac(32px, 24px) * 4);
        @mixin max-line-length 4;
    }

    &__btn {
        margin-top: auto;
        display: inline-flex;
        align-items: center;
        color: var(--cl-white);
        font-weight: 600;
        cursor: pointer;
        transition: .3s ease;

        &:hover {
            color: var(--cl-yellow);
        }

        &>span {
            margin-right: 9px;
            width: 16px;
            height: 16px;
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none'%3E%3Cpath stroke='%23E4B700' stroke-linejoin='bevel' stroke-width='3' d='M1 2.384h12.767V15.15m-12.22-1.192L13.156 2.352'/%3E%3C/svg%3E") center / contain no-repeat;
        }
    }

    &__icon {
        margin-top: auto;
        width: 43px;
        height: 42px;
        margin-left: auto;
        transform: translate(ac(12px, 0px), ac(8px, 0px));

        img {
            object-fit: contain;
            object-position: right center;
        }
    }

    @mixin max-md {
        &__wrapper {
            &>.btn {
                margin: 15px auto 0;
            }
        }

        h2 {
            text-align: center;
            width: 100%;
        }

        &__card {
            width: calc(100% - ac(20px, 15px));
            min-height: unset;
        }

        &__descr {
            margin-bottom: 10px;
        }
    }
}