.team {
    margin-top: ac(98px, 40px);

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        max-width: ac(950px, 743px);
        margin-right: ac(-20px, -15px);
        margin-bottom: ac(-20px, -15px);
    }

    &__card {
        width: calc(100% / 3 - ac(20px, 15px));
        margin-right: ac(20px, 15px);
        margin-bottom: ac(20px, 15px);
        background-color: var(--cl-light-blue);
    }

    &__img {
        position: relative;
        width: 100%;
        height: ac(304px, 260px);

        .arrow-btn {
            position: absolute;
            bottom: ac(30px, 15px);
            right: ac(30px, 15px);

            border-color: var(--cl-white);
            background-color: var(--cl-white);

            &__icon {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none'%3E%3Cpath stroke='%23061654' stroke-linejoin='bevel' stroke-width='3' d='M1 2.384h12.766V15.15m-12.22-1.193L13.155 2.351'/%3E%3C/svg%3E");
            }

            &:hover {
                background-color: var(--cl-yellow);
                transform: rotate(45deg);
            }
        }
    }

    &__bottom {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: ac(27px, 15px) ac(30px, 15px);
    }

    &__name {
        width: 100%;
        font-weight: 600;
        font-size: ac(22px, 18px);
        line-height: ac(32px, 25px);
        padding-bottom: 8px;
        margin-bottom: ac(12px, 8px);
        border-bottom: 1px solid var(--cl-tealish);
    }

    &__pos {
        margin-bottom: ac(8px, 5px);
    }

    &__pos, &__link {
        font-family: var(--font-sec);
    }

    &__link {
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        transition: .3s ease;
        &:not(:last-child) {
            margin-bottom: ac(8px, 5px);
        }

        span {
            width: 13px;
            margin-right: ac(10px, 5px);
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            transition: .3s ease;
        }

        &--msn {
            span {
                height: 9px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='9' fill='none'%3E%3Cpath fill='%23061654' d='M6.5 4.856.008 0h12.984L6.5 4.856Zm0 1.288L0 1.28V9h13V1.281L6.5 6.144Z'/%3E%3C/svg%3E");
            }

            &:hover {
                span {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='9' fill='none'%3E%3Cpath fill='%23E4B700' d='M6.5 4.856.008 0h12.984L6.5 4.856Zm0 1.288L0 1.28V9h13V1.281L6.5 6.144Z'/%3E%3C/svg%3E");
                }
            }
        }

        &--tel {
            span {
                height: 13px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='13' fill='none'%3E%3Cpath fill='%23061654' d='M11.037 8.377a6.813 6.813 0 0 1-2.14-.341c-.335-.114-.746-.01-.95.2l-1.35 1.019C5.032 8.42 4.068 7.456 3.244 5.903l.99-1.314a.97.97 0 0 0 .238-.983 6.824 6.824 0 0 1-.342-2.143A.964.964 0 0 0 3.167.5H.963A.964.964 0 0 0 0 1.463C0 7.549 4.951 12.5 11.037 12.5c.531 0 .963-.432.963-.963V9.34a.964.964 0 0 0-.963-.962Z'/%3E%3C/svg%3E");
            }
            
            &:hover {
                span {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='13' fill='none'%3E%3Cpath fill='%23E4B700' d='M11.037 8.377a6.813 6.813 0 0 1-2.14-.341c-.335-.114-.746-.01-.95.2l-1.35 1.019C5.032 8.42 4.068 7.456 3.244 5.903l.99-1.314a.97.97 0 0 0 .238-.983 6.824 6.824 0 0 1-.342-2.143A.964.964 0 0 0 3.167.5H.963A.964.964 0 0 0 0 1.463C0 7.549 4.951 12.5 11.037 12.5c.531 0 .963-.432.963-.963V9.34a.964.964 0 0 0-.963-.962Z'/%3E%3C/svg%3E");
                }
            }
        }

        &--in {
            span {
                transform: translateY(-2px);
                height: 13px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='13' fill='none'%3E%3Cpath fill='%23061654' d='M2.697 1.354c0 .748-.6 1.354-1.343 1.354A1.349 1.349 0 0 1 .011 1.354C.01.607.612 0 1.354 0s1.343.607 1.343 1.354Zm.011 2.438H0v8.666h2.708V3.792Zm4.324 0H4.34v8.666h2.691v-4.55c0-2.529 3.266-2.736 3.266 0v4.55H13V6.971c0-4.269-4.833-4.113-5.968-2.012V3.792Z'/%3E%3C/svg%3E");
            }
            &:hover {
                span {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='13' fill='none'%3E%3Cpath fill='%23E4B700' d='M2.697 1.354c0 .748-.6 1.354-1.343 1.354A1.349 1.349 0 0 1 .011 1.354C.01.607.612 0 1.354 0s1.343.607 1.343 1.354Zm.011 2.438H0v8.666h2.708V3.792Zm4.324 0H4.34v8.666h2.691v-4.55c0-2.529 3.266-2.736 3.266 0v4.55H13V6.971c0-4.269-4.833-4.113-5.968-2.012V3.792Z'/%3E%3C/svg%3E");
                }
            }
        }


        &:hover {
            color: var(--cl-yellow);
        }
    }

    @mixin max-md {
        &__card {
            width: calc(100% / 2 - ac(20px, 15px));
        }

        &__img {
            height: 320px;
        }
    }

    @mixin max-sm {
        &__wrapper {
            max-width: ac(420px, 280px, 320, 640);
            margin-right: 0;
        }

        &__card {
            width: 100%;
            margin-right: 0;
        }

        &__img {
            height: ac(464px, 300px, 320, 768);
        }
    }
}