@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;600&display=swap');

@font-face {
  font-family:"proxima-nova";
  src:url("https://use.typekit.net/af/2555e1/00000000000000007735e603/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/2555e1/00000000000000007735e603/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/2555e1/00000000000000007735e603/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  font-display:swap;font-style:normal;font-weight:700;font-stretch:normal;
  }
  
  @font-face {
  font-family:"proxima-nova";
  src:url("https://use.typekit.net/af/78aca8/00000000000000007735e60d/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff2"),url("https://use.typekit.net/af/78aca8/00000000000000007735e60d/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff"),url("https://use.typekit.net/af/78aca8/00000000000000007735e60d/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("opentype");
  font-display:swap;font-style:normal;font-weight:600;font-stretch:normal;
  }
  
  @font-face {
  font-family:"proxima-nova";
  src:url("https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display:swap;font-style:normal;font-weight:400;font-stretch:normal;
  }
  
:root {
  --cl-black: #000000;
  --cl-white: #ffffff;
  --cl-tealish: #05153b;
  --cl-light-blue: #EAEDF6;
  --cl-blue: #04143A;
  --cl-dark-blue: #061654;
  --cl-yellow: #E4B700;
  --cl-gray: rgb(234, 237, 246);
  --font-main: 'Comfortaa', sans-serif;
  --font-sec: 'proxima-nova', sans-serif;

  --vh: calc(100vh / 100);
}