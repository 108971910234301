.refer {
    position: relative;
    margin-top: ac(57px, 35px);

    &--article {
        position: relative;
        margin-top: ac(100px, 40px);
        padding-bottom: ac(100px, 40px);

        &::before {
            content: '';
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            width: calc(100% - 40px);
            height: 2px;
            max-width: 1276px;
            background-color: var(--cl-tealish);
        }

        .refer {
            &__title {
                max-width: 470px;
                margin-bottom: ac(30px, 15px);
            }
        }
    }

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: ac(-20px, -15px);
        margin-right: ac(-20px, -15px);
    }

    &__card {
        padding: ac(50px, 15px);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        width: calc(100% / 2 - ac(20px, 15px));
        margin-bottom: ac(20px, 15px);
        margin-right: ac(20px, 15px);
        overflow: hidden;
        cursor: pointer;

        &:nth-child(1) {
            background-color: #04143A;

            .refer__img {
                width: ac(280px, 120px);
                height: 290px;
            }
        }

        &:nth-child(2) {
            background-color: var(--cl-dark-blue);

            .refer__img {
                width: ac(351px, 150px);
                height: 232px;
            }
        }

        &:hover {
            .btn--wh {
                background-color: var(--cl-yellow);

                .btn__text {
                    color: var(--cl-white);
                }

                .arrow-btn {
                    background-color: var(--cl-white);
                }

                .arrow-btn__icon {
                    transform: rotate(45deg);
                }
            }
        }
    }

    &__img {
        position: absolute;
        right: 0;
        bottom: 0;

        img {
            object-fit: contain;
            object-position: right bottom;
        }
    }

    &__title {
        margin-bottom: ac(50px, 25px);
        max-width: 275px;
        font-size: ac(40px, 26px);
        line-height: ac(55px, 36px);
        font-weight: 600;
        color: var(--cl-white);

        + .btn {
            margin-top: auto;
        }
    }

    @mixin max-md {
        &__card {
            width: 100%;
            padding: ac(50px, 15px, 320, 768);

            &:nth-child(1) {
                .refer__img {
                    width: ac(200px, 100px, 320, 768);
                }
            }
    
            &:nth-child(2) {
                .refer__img {
                    width: ac(300px, 110px, 375, 768);
                    img {
                        object-position: ac(0, 16px, 320, 420) bottom;
                    }
                    @media (max-width: 374px) {
                        width: 117px;
                    }
                }

            }
        }
    }
}