.details {
    &--article {
        margin-top: ac(98px, 50px);

        .details {
            &__text {
                padding: ac(60px, 40px) ac(216px, 0px) 0;
                margin-right: 0;
                max-width: unset;
                border-top: 2px solid var(--cl-blue);
            }

            &__share-box {
                margin-top: ac(81px, 35px);

                span {
                    margin-bottom: 9px;
                }
            }

            &__highlights {
                position: relative;
                padding-bottom: 0;
                margin-top: ac(61px, 30px);
                border: 0;

                a {
                    color: var(--cl-blue);
                    text-decoration: none;

                    &:hover {
                        color: var(--cl-yellow);
                    }
                }
            }
        }
    }

    &--talent {
        .details__text {
            ul {
                li {
                    padding-left: ac(27px, 20px);
                    position: relative;

                    &::before {
                        content: '';
                        position: absolute;
                        left: ac(5px, 0px);
                        top: ac(5px, 2px);
                        width: 15px;
                        height: 15px;
                        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' fill='none'%3E%3Cpath fill='%23E4B700' d='m7.3 0 1.968 5.595L15 5.381l-4.515 3.672 1.971 5.596L7.7 11.328 3.188 15l1.569-5.729L0 5.947l5.728-.218L7.301 0Z'/%3E%3C/svg%3E") center / contain no-repeat;
                    }
                }
            }
        }
    }

    &__highlights {
        margin-top: ac(58px, 30px);
        padding: ac(62px, 30px) 0 ac(59px, 30px);
        border-top: 2px solid var(--cl-blue);
        border-bottom: 2px solid var(--cl-blue);
    }

    &__highlights-box {
        display: flex;
        flex-wrap: wrap;
        margin-top: 19px;
        margin-bottom: -5px;
    }

    &__highlights-content {
        width: 100%;
        max-width: ac(850px, 600px, 640, 1440);
        margin-bottom: 5px;
        column-count: 2;

        &>* {
            position: relative;
            padding-left: ac(33px, 25px);
            font-weight: 400;
            font-size: ac(22px, 18px);
            line-height: ac(25px, 22px);
            font-family: var(--font-sec);
            margin-bottom: ac(10px, 5px);

            &::before {
                content: '';
                position: absolute;
                top: ac(5px, 3px);
                left: ac(9px, 0px);
                width: 15px;
                height: 15px;
                background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' fill='none'%3E%3Cpath fill='%23E4B700' d='m7.3 0 1.968 5.595L15 5.381l-4.515 3.672 1.971 5.596L7.7 11.328 3.188 15l1.569-5.729L0 5.947l5.728-.218L7.301 0Z'/%3E%3C/svg%3E") center / contain no-repeat;
            }
        }

        ul {
            padding-left: 0;
            li {
                font-weight: 400;
                font-size: ac(22px, 18px);
                line-height: ac(25px, 22px);
                font-family: var(--font-sec);
                margin-bottom: ac(10px, 5px);
                position: relative;
                padding-left: ac(33px, 25px);

                &::before {
                    content: '';
                    position: absolute;
                    top: ac(5px, 3px);
                    left: ac(9px, 0px);
                    width: 15px;
                    height: 15px;
                    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' fill='none'%3E%3Cpath fill='%23E4B700' d='m7.3 0 1.968 5.595L15 5.381l-4.515 3.672 1.971 5.596L7.7 11.328 3.188 15l1.569-5.729L0 5.947l5.728-.218L7.301 0Z'/%3E%3C/svg%3E") center / contain no-repeat;
                }
            }
        }

        @mixin max-sm {
            column-count: 1;
        }
    }

    &__wrapper {
        display: flex;
        margin-top: ac(60px, 30px);
    }

    &__text {
        max-width: ac(952px, 500px);
        flex-shrink: 0;
        margin-right: ac(128px, 20px);
        width: 100%;

        img {
            object-fit: contain;
            height: auto !important;
        }

        h1 {
            margin-top: .8em;
            margin-bottom: .4em;
        }

        h2 {
            &:not(:first-child) {
                margin-top: ac(40px, 25px);
            }
            &:not(:last-child) {
                margin-bottom: ac(30px, 20px);
            }
            font-size: ac(25px, 20px);
            line-height: ac(39px, 28px);
        }

        h3 {
            &:not(:first-child) {
                margin-top: ac(30px, 20px);
            }
            &:not(:last-child) {
                margin-bottom: ac(20px, 15px);
            }
            font-family: var(--font-sec);
            font-size: ac(22px, 18px);
            line-height: ac(32px, 26px);
        }

        h4,h5,h6 {
            font-family: var(--font-sec);
            &:not(:first-child) {
                margin-top: ac(30px, 20px);
            }
            &:not(:last-child) {
                margin-bottom: ac(20px, 15px);
            }
        }

        h4 {
            font-size: ac(20px, 18px);
            line-height: ac(30px, 24px);
        }

        h5 {
            font-size: ac(18px, 16px);
            line-height: ac(28px, 22px);
        }

        h6 {
            font-size: 16px;
            line-height: 22px;
        }

        &>ul, &>ol {
            &:not(:last-child) {
                margin-bottom: ac(30px, 15px);
            }
        }

        p {
            &:not(:last-child) {
                margin-bottom: ac(20px, 10px);
            }

            &+ol {
                margin-top: ac(-10px, 0px);
            }
        }

        p, li {
            font-family: var(--font-sec);
            font-size: ac(18px, 16px);
            line-height: ac(25px, 20px);

            a {
                display: inline-block;
                color: var(--cl-yellow);
                text-decoration: underline;
                word-break: break-word;
                transition: .3s ease;
    
                &:hover {
                    text-decoration: none;
                }
            }
        }

        ul, ol {
            li {
                &:not(:last-child) {
                    margin-bottom: 5px;
                }

                ul, ol {
                    margin-top: 5px;
                }
            }
        }

        ul {
            &>li {
                position: relative;
                padding-left: ac(27px, 20px);

                &::before {
                    content: '';
                    position: absolute;
                    top: ac(10px, 8px);
                    left: ac(12px, 5px);
                    width: 4px;
                    height: 4px;
                    border-radius: 100%;
                    background-color: var(--cl-tealish);
                }
            }
        }

        ul {
            padding-left: 0;
            li {
                font-size: ac(18px, 16px);
                line-height: ac(25px, 20px);
                margin-bottom: 5px;
                position: relative;
                padding-left: ac(27px, 20px);

                &::before {
                    content: '';
                    position: absolute;
                    top: ac(5px, 3px);
                    left: ac(9px, 0px);
                    width: 15px;
                    height: 15px;
                    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' fill='none'%3E%3Cpath fill='%23E4B700' d='m7.3 0 1.968 5.595L15 5.381l-4.515 3.672 1.971 5.596L7.7 11.328 3.188 15l1.569-5.729L0 5.947l5.728-.218L7.301 0Z'/%3E%3C/svg%3E") center / contain no-repeat;
                }
            }
        }

        ol {
            list-style-type: auto;
            list-style-position: inside;
        }

        .btn {
            margin-top: ac(48px, 30px);
        }
    }

    &__tags {
        margin-top: ac(48px, 30px);

        a {
            font-weight: 700;
            font-size: 16px;
            font-family: var(--font-sec);
            color: var(--cl-yellow);
            text-transform: uppercase;
        }
    }

    &__col {
        width: 100%;
    }

    &__card {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 24px;

        background-color: var(--cl-white);
        box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.07);

        .btn {
            max-width: 164px;
            width: calc(100% + ac(15px, 0px));
            margin-top: 20px;

            &__text {
                margin-right: 8px;
            }
        }
    }

    &__img {
        width: 98px;
        height: 98px;

        img {
            border-radius: 100%;
        }
    }

    &__name, &__position {
        font-family: var(--font-sec);
        text-align: center;
        font-size: 18px;
        line-height: 25px;
    }

    &__name {
        font-weight: 600;
        margin-top: 15px;
    }

    &__position {
        margin-top: 2px;
    }

    &__contact-box {
        margin-top: 19px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin-right: -17px;
        margin-bottom: -10px;
    }

    &__contact {
        margin-right: 17px;
        margin-bottom: 10px;
        height: 24px;

        background-size: contain;
        background-repeat: no-repeat;
        background-position: center bottom;
        transition: .3s ease;

        &--fb {
            width: 12px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='24' fill='none'%3E%3Cpath fill='%23061654' d='M3 8H0v4h3v12h5V12h3.642L12 8H8V6.333C8 5.378 8.192 5 9.115 5H12V0H8.192C4.596 0 3 1.583 3 4.615V8Z'/%3E%3C/svg%3E");

            &:hover {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='24' fill='none'%3E%3Cpath fill='%23E4B700' d='M3 8H0v4h3v12h5V12h3.642L12 8H8V6.333C8 5.378 8.192 5 9.115 5H12V0H8.192C4.596 0 3 1.583 3 4.615V8Z'/%3E%3C/svg%3E");
            }
        }

        &--msn {
            width: 24px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='18' fill='none'%3E%3Cpath fill='%23061654' d='M12 9.713.015 0h23.97L12 9.713Zm0 2.574L0 2.562V18h24V2.562l-12 9.725Z'/%3E%3C/svg%3E");

            &:hover {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='18' fill='none'%3E%3Cpath fill='%23E4B700' d='M12 9.713.015 0h23.97L12 9.713Zm0 2.574L0 2.562V18h24V2.562l-12 9.725Z'/%3E%3C/svg%3E");
            }
        }

        &--tel {
            width: 22px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22' fill='none'%3E%3Cpath fill='%23061654' d='M20.235 14.44c-1.347 0-2.67-.21-3.923-.624-.614-.21-1.369-.018-1.744.367l-2.473 1.868c-2.87-1.532-4.636-3.298-6.147-6.145l1.813-2.41c.47-.47.64-1.157.437-1.801a12.51 12.51 0 0 1-.627-3.93C7.57.792 6.779 0 5.806 0h-4.04C.791 0 0 .792 0 1.765 0 12.923 9.077 22 20.235 22c.973 0 1.765-.792 1.765-1.765v-4.03c0-.973-.792-1.764-1.765-1.764Z'/%3E%3C/svg%3E");

            &:hover {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22' fill='none'%3E%3Cpath fill='%23E4B700' d='M20.235 14.44c-1.347 0-2.67-.21-3.923-.624-.614-.21-1.369-.018-1.744.367l-2.473 1.868c-2.87-1.532-4.636-3.298-6.147-6.145l1.813-2.41c.47-.47.64-1.157.437-1.801a12.51 12.51 0 0 1-.627-3.93C7.57.792 6.779 0 5.806 0h-4.04C.791 0 0 .792 0 1.765 0 12.923 9.077 22 20.235 22c.973 0 1.765-.792 1.765-1.765v-4.03c0-.973-.792-1.764-1.765-1.764Z'/%3E%3C/svg%3E");
            }
        }
    }

    &__sep {
        margin-top: 20px;
        height: 1px;
        width: 100%;
        background-color: var(--cl-dark-blue);
    }

    &__share-box {
        margin-top: 19px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-right: -19px;
        margin-bottom: -10px;

        span {
            margin-bottom: 9px;
            width: 100%;
            font-weight: 600;
            font-size: ac(22px, 18px);
            line-height: ac(32px, 26px);
            font-family: var(--font-sec);
        }

        .details__contact {
            margin-right: 19px;
        }

        .details__contact--in {
            width: 24px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='23' fill='none'%3E%3Cpath fill='%23E4B700' d='M4.98 2.5A2.49 2.49 0 0 1 2.5 5 2.49 2.49 0 0 1 .02 2.5C.02 1.12 1.13 0 2.5 0a2.49 2.49 0 0 1 2.48 2.5ZM5 7H0v16h5V7Zm7.982 0H8.014v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0V23H24V12.869c0-7.88-8.922-7.593-11.018-3.714V7Z'/%3E%3C/svg%3E");

            &:hover {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='23' fill='none'%3E%3Cpath fill='%23061654' d='M4.98 2.5A2.49 2.49 0 0 1 2.5 5 2.49 2.49 0 0 1 .02 2.5C.02 1.12 1.13 0 2.5 0a2.49 2.49 0 0 1 2.48 2.5ZM5 7H0v16h5V7Zm7.982 0H8.014v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0V23H24V12.869c0-7.88-8.922-7.593-11.018-3.714V7Z'/%3E%3C/svg%3E");
            }
        }

        .details__contact--x {
            width: 22px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='20' fill='none'%3E%3Cpath fill='%23E4B700' d='M16.99 0h3.308l-7.227 8.26 8.502 11.24h-6.657l-5.214-6.817L3.736 19.5H.426l7.73-8.835L0 0h6.826l4.713 6.231L16.99 0Zm-1.161 17.52h1.833L5.83 1.876H3.863L15.829 17.52Z'/%3E%3C/svg%3E");

            &:hover {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='20' fill='none'%3E%3Cpath fill='%23061654' d='M16.99 0h3.308l-7.227 8.26 8.502 11.24h-6.657l-5.214-6.817L3.736 19.5H.426l7.73-8.835L0 0h6.826l4.713 6.231L16.99 0Zm-1.161 17.52h1.833L5.83 1.876H3.863L15.829 17.52Z'/%3E%3C/svg%3E");
            }
        }

        .details__contact--msn {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='18' fill='none'%3E%3Cpath fill='%23E4B700' d='M12 9.713.015 0h23.97L12 9.713Zm0 2.574L0 2.562V18h24V2.562l-12 9.725Z'/%3E%3C/svg%3E");

            &:hover {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='18' fill='none'%3E%3Cpath fill='%23061654' d='M12 9.713.015 0h23.97L12 9.713Zm0 2.574L0 2.562V18h24V2.562l-12 9.725Z'/%3E%3C/svg%3E");
            }
        }

        .details__contact--fb {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='24' fill='none'%3E%3Cpath fill='%23E4B700' d='M3 8H0v4h3v12h5V12h3.642L12 8H8V6.333C8 5.378 8.192 5 9.115 5H12V0H8.192C4.596 0 3 1.583 3 4.615V8Z'/%3E%3C/svg%3E");

            &:hover {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='24' fill='none'%3E%3Cpath fill='%23061654' d='M3 8H0v4h3v12h5V12h3.642L12 8H8V6.333C8 5.378 8.192 5 9.115 5H12V0H8.192C4.596 0 3 1.583 3 4.615V8Z'/%3E%3C/svg%3E");
            }
        }
    }

    @mixin lg {
        &__card {
            position: sticky;
            top: 90px;
            width: 100%;
        }
    }

    @mixin max-lg {
        &__wrapper {
            flex-direction: column-reverse;
        }

        &__share-box {
            justify-content: center;

            span {
                margin-right: 19px;
                text-align: center;
            }
        }

        &__card {
            margin: 0 auto 25px;
            max-width: 500px;
        }

        &__text {
            max-width: unset;
            margin-right: 0;
        }
    }

    @mixin max-xs {
        .details__card .btn {
            width: auto;
        }
    }
}