.feedback {
    position: relative;
    padding: ac(157px, 120px) 0 ac(60px, 30px);
    min-height: 100vh;
    background-color: var(--cl-tealish);

    &__wrap {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        @mixin media 951 {
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
        }
    }

    &__content {
        width: 30%;
        max-width: 342px;

        .footer__title-box {
            margin-top: 0;
            margin-bottom: 0;
        }

        .footer__title {
            font-size: ac(40px, 22px);
            line-height: ac(55px, 26px);
            margin-bottom: ac(20px, 14px);
        }

        .footer__descr {
            p {
                &:not(:first-child) {
                    color: var(--cl-yellow);
                }
            }

        }

        @mixin media 951 {
            width: 100%;
            margin-bottom: 40px;
            max-width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            .footer__title-box {
                width: 50%;
            }
        }

        @mixin media 551 {
            .footer__title-box {
                width: 100%;
            }
        }
    }

    &__content-bg {
        margin-top: ac(59px, 24px);
        @mixin aspect-ratio-block 337.86, 387.93;
        width: 98.79%;

        @mixin media 951 {
            margin-top: 0;
            width: 40%;
            max-width: 240px;
        }

        @mixin media 551 {
            display: none;
        }
    }

    &__content-image {
        @mixin aspect-ratio 384.86, 387.93;
        position: absolute;
        top: 0;
        right: 0;
        width: 113.91%;

        img {
            object-fit: contain;
        }
    }

    .footer__form {
        .footer__star {
            &:before {
                -webkit-text-stroke: 2px rgba(255, 255, 255, 0.3);
                font-size: ac(58px, 40px);
            }

            &:hover {
                -webkit-text-stroke: 2px var(--cl-yellow);
            }
        }

        .footer__input-box {
            &.with-additional-info {
                width: 100%;
                justify-content: space-between;
                align-items: center;

                .footer__label {
                    width: 100%;
                }

                input, .additional-info {
                    width: calc(50% - ac(10px, 5px));
                }

                .additional-info {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    min-height: 55px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 17px;
                    color: #FFFFFF;
                }

                @mixin max-sm {
                    flex-direction: column;
                    align-items: flex-start;

                    input, .additional-info {
                        width: 100%;
                    }

                    .additional-info {
                        margin-top: 10px;
                    }
                }
            }
        }

        @mixin media 1100 {
            width: 66.14%;
            max-width: 1000px;
        }

        @mixin media 951 {
            width: 100%;
        }
    }

    &.feedback-pay-success {
        .footer__btn-box {
            @media (max-width: 1390px) {
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;

                @media (min-width: 641px) {
                    grid-row-gap: 32px;
                }

                .footer__btn {
                    margin-left: 0;
                }
            }
        }
    }
}