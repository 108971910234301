.star-text {
    margin-top: ac(100px, 60px);
    padding: ac(60px, 30px) 0;

    &::before, &::after {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 40px);
        height: 2px;
        max-width: 1276px;
        background-color: var(--cl-tealish);
    }

    &::before {
        top: 0;
    }

    &::after {
        bottom: 0;
    }

    &__wrapper {
        position: relative;
        max-width: ac(844px, 500px, 640, 1440);
        margin-left: ac(216px, 0px, 640, 1440);
    }

    &__decor {
        position: absolute;
        top: ac(-53px, -10px);
        right: ac(-148px, -120px);
        width: ac(220px, 100px);
        height: ac(267px, 140px);

        img {
            object-fit: contain;
        }
    }

    &__subtitle {
        font-weight: 600;
        margin-top: ac(21px, 15px);
        font-size: ac(25px, 18px);
        line-height: ac(39px, 25px);
    }

    &__text {
        margin-top: ac(21px, 15px);
        font-weight: 400;
        font-size: ac(22px, 18px);
        line-height: 1.45;
    }

    &__buttons {
        margin-top: ac(28px, 15px);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: ac(20px, 10px);

        &.with-absolute {
            position: relative;
            a {
                cursor: pointer;
            }
            @media (min-width: 1230px) {
                .btn:first-of-type {
                    position: absolute;
                    right: -30px;
                    top: -30px;
                    transform: translateY(-100%) translateX(50%);
                }
            }
        }
    }

    &__btn {
        margin-top: ac(28px, 15px);
    }

    &--reg {

       &, &+.star-text {
           margin-top: ac(60px, 40px);
       }

        .star-text__wrapper {
            display: flex;
            margin-left: 0;
            max-width: unset;
            flex-direction: row;
            flex-wrap: wrap;
            margin-bottom: -20px;

            &>* {
                margin-bottom: 20px;
            }
        }

        .star-text__title {
            margin-right: 10px;
        }

        .star-text__subtitle {
            max-width: 736px;
            font-weight: 400;
            font-family: var(--font-sec);
            font-size: ac(22px, 18px);
            line-height: ac(32px, 25px);
        }

        .star-text__btn {
            margin-top: 0;
            margin-left: auto;
        }
    }

    &--talent {
        margin-top: 57px;
        padding: ac(61px, 30px) 0 ac(59px, 30px);

        &+.star-text--talent {
            margin-top: 0;
            padding-bottom: 0;

            &::before, &::after {
                display: none;
            }

            .star-text__title {
                margin-bottom: 21px;
            }

            p:first-of-type {
                font-size: 18px;
            }

            p {
                max-width: 952px;
                line-height: 23px;
            }
        }
        .star-text__wrapper {
            margin-left: 0;
            max-width: unset;
            margin-bottom: ac(-30px, -20px);

            p {
                font-family: var(--font-sec);
            }

            &>*:not(.star-text__title) {
                margin-bottom: ac(30px, 20px);
            }
        }
        .star-text__title {
            margin-bottom: ac(31px, 20px);
        }

        .star-text__btn {
            margin-top: 0;
            margin-right: 15px;
            margin-bottom: ac(30px, 20px);
        }
    }

    &--alt,
    &--details {
        .star-text {
            &__decor {
                top: 47%;
                right: ac(-363px, -140px);
                width: ac(358px, 180px);
                height: ac(402px, 160px);
                transform: translateY(-50%);
            }

            &__wrapper {
                margin-left: 0;
            }

            &__subtitle {
                font-size: ac(30px, 22px);
                line-height: 140%;
            }
        }
    }

    &--alt {
        .star-text {
            &__subtitle {
                font-size: ac(25px, 18px);
                line-height: 140%;
            }
        }
    }

    &--details {
        margin-top: ac(97px, 40px);

        .star-text {
            /*&__decor {
                top: ac(-80px, -10px);
            }*/

            &__subtitle {
                a {
                    margin-left: ac(10px, 0px);
                    font-weight: 600;
                    font-size: 18px;
                    color: var(--cl-dark-blue);
                    transition: .3s ease;

                    &:hover {
                        color: var(--cl-yellow);
                    }
                }
            }
        }
    }

    &--country {
        margin-top: ac(100px, 50px);

        .star-text {
            &__decor {
                top: ac(-62px, -10px);
                right: ac(-156px, -120px);
                width: ac(220px, 160px);
                height: ac(267px, 180px);
            }

            &__wrapper {
                max-width: ac(982px, 500px, 640, 1440);
                /* margin-left: ac(108px, 0px, 640, 1440); */
                margin-left: 0;
            }

            &__subtitle {
                max-width: 736px;
                font-weight: 400;
                font-size: ac(22px, 18px);
                line-height: ac(32px, 25px);
                font-family: var(--font-sec);
            }

        }
    }

    &--sub-head,
    &--services {
        .star-text {
            &__wrapper {
                max-width: ac(952px, 500px, 640, 1440);
                margin-left: ac(108px, 0px, 640, 1440);
            }

            &__subtitle {
                max-width: 736px;
                font-weight: 400;
                font-family: var(--font-sec);
                font-size: ac(22px, 18px);
                line-height: ac(32px, 25px);
            }
        }
    }

    &--sub-head {
        margin-top: 58px;

        .star-text {
            &__subtitle {
                margin-top: 2px;
                max-width: unset;

                h2 {
                    font-family: var(--font-main);
                    margin-bottom: ac(21px, 15px);
                }

                p {
                    max-width: 736px;
                    max-width: 736px;
                }

                ul {
                    padding-left: 0;
                    li {
                        font-weight: 400;
                        font-size: ac(22px, 18px);
                        line-height: ac(25px, 22px);
                        font-family: var(--font-sec);
                        margin-bottom: ac(10px, 5px);
                        position: relative;
                        padding-left: ac(33px, 25px);

                        &::before {
                            content: '';
                            position: absolute;
                            top: ac(5px, 3px);
                            left: ac(9px, 0px);
                            width: 15px;
                            height: 15px;
                            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' fill='none'%3E%3Cpath fill='%23E4B700' d='m7.3 0 1.968 5.595L15 5.381l-4.515 3.672 1.971 5.596L7.7 11.328 3.188 15l1.569-5.729L0 5.947l5.728-.218L7.301 0Z'/%3E%3C/svg%3E") center / contain no-repeat;
                        }
                    }
                }
            }
        }
    }

    @mixin max-sm {
        &, &--alt, &--sub-head, &--services, &--country, &--details {
            .star-text__wrapper {
                max-width: unset;
            }

            .star-text__decor {
                display: none;
            }
        }

    }
}