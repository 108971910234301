/*
Styles for popup background and basic styles for fonts
*/

.termsfeed-com---pc-dialog input[type="checkbox"].cc-custom-checkbox {
  display: none;
  pointer-events: none;
  opacity: 0;
}

.termsfeed-com---palette-dark.termsfeed-com---nb {
  border: 2px solid var(--cl-yellow);
  border-right: 0;
  border-bottom: 0;
  background: var(--cl-dark-blue);
  color: var(--cl-white);
  font-family: var(--font-main);
  border-radius: 0 0 0 0;
  .cc-nb-okagree,
  .cc-nb-reject,
  .cc-nb-changep {
    margin-right: 0 !important;
    margin-bottom: 0x !important;
    margin-top: 0 !important;
    text-transform: capitalize;
  }
  .cc-nb-title,
  .cc-nb-text {
    color: var(--cl-white);
  }
  #cc-nb-text {
    font-weight: 400;
  }
  #cc-nb-title {
    color: var(--cl-white);
    font-weight: 600;
  }

  @media (max-width: 380px) {
    border-left: none;
  }
}
.termsfeed-com---nb {
  /*
    Styles for buttons
    */
  .cc-nb-okagree,
  .cc-nb-reject,
  .cc-nb-changep,
  .cc-cp-foot-button .cc-cp-foot-save {
    position: relative;
    width: fit-content;
    z-index: 2;
    cursor: pointer;
    overflow: hidden;
    padding: 0 18px 0 60px;
    background: var(--cl-dark-blue);
    display: inline-flex;
    border: 2px solid var(--cl-white);
    border-radius: 24px;
    transition: .3s ease;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 8px;
    height: 48px;
    font-weight: 600;
    line-height: 1.2;
    color: var(--cl-white);
    text-transform: capitalize !important;
    font-size: 16px;

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -2px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: 48px;
      height: 48px;
      border: 2px solid var(--cl-white);
      border-radius: 50%;
      background-color: var(--cl-white);
      transition: .3s ease;
      z-index: 4;
    }

    &:before {
      content: "";
      width: 16px;
      height: 16px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none'%3E%3Cpath stroke='%23E4B700' stroke-linejoin='bevel' stroke-width='3' d='M1 2.384h12.766V15.15m-12.22-1.193L13.155 2.351'/%3E%3C/svg%3E");
      transition: .3s ease;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 16px;
      z-index: 5;
    }

    &:focus {
      box-shadow: none !important;
      border: none !important;
    }
    &:hover {
      background-color: var(--cl-yellow);
      color: var(--cl-white);

      &:after {
        background-color: var(--cl-white);
      }

      &:before {
        transform: translateY(-50%) rotate(45deg);
      }
    }
  }
  /*.cc-nb-reject {
    background: var(--cl-white);
    color: var(--cl-dark-blue);
    border-color: var(--cl-yellow);

    &:after {
      border-color: var(--cl-yellow);
    }

    &:hover {
      background: var(--cl-dark-blue);
      color: var(--cl-white);
    }
  }*/
  .cc-nb-buttons-container {
    display: flex !important;
    flex-wrap: wrap;
    gap: ac(20px, 12px);
    grid-row-gap: 0;
  }
}
/*
Styles for popup with settings
*/
.termsfeed-com---palette-dark {
  .cc-pc-container {
    font-family: var(--font-main) !important;
    border: 2px solid var(--cl-yellow);
    border-radius: 0;
    overflow: hidden;
  }
  .cc-pc-head,
  .cc-cp-body-content,
  .cc-cp-foot {
    background: var(--cl-dark-blue) !important;
    border-top-color: var(--cl-yellow) !important;
    border-bottom-color: var(--cl-yellow) !important;
  }
  .cc-cp-body-tabs {
    font-family: var(--font-main) !important;
    border-right: 1px solid var(--cl-yellow);
  }
  .cc-cp-body-tabs {
    background-color: var(--cl-dark-blue);
  }
  .cc-cp-body-tabs-item {
    border-color: var(--cl-yellow) !important;
    background: var(--cl-dark-blue);
  }
  .cc-cp-body-tabs-item-link {
    color: var(--cl-white);
    transition: all .3s ease;
    &:focus {
      box-shadow: none;
    }

    &:hover {
      color: var(--cl-yellow);
    }
  }
  .cc-cp-body-tabs-item[active='true'] {
    background: var(--cl-yellow) !important;
    .cc-cp-body-tabs-item-link {
      color: var(--cl-dark-blue) !important;
    }
  }

  .cc-pc-head-lang {
    select {
      color: var(--cl-white);
      padding: 5px;
      background: var(--cl-dark-blue);
      border: 1px solid var(--cl-white);
      cursor: pointer;
      &:focus {
        box-shadow: none;
      }
      option {
        font-size: 16px;
        font-weight: 300;
        line-height: 1.6;
        letter-spacing: -0.1px;
        cursor: pointer;
      }
    }
  }
  .cc-pc-head-close {
    color: var(--cl-white);
    transition: all 0.3s ease;
    &:hover {
      color: var(--cl-yellow);
      transform: scale(1.1);
    }
    &:focus {
      border: 0;
    }
  }
}
.termsfeed-com---pc-dialog input[type="checkbox"].cc-custom-checkbox:checked + label::before {
  background-color: var(--cl-yellow);
}
/*
Styles for the lower part of the popup with settings
*/
.termsfeed-com---pc-dialog {
  .cc-cp-body-tabs {
    font-family: var(--font-main) !important;
  }
  .cc-cp-foot {
    padding: 10px 0;
    flex-wrap: wrap;
  }
  .cc-cp-foot-byline {
    a {
      color: var(--cl-yellow);
      text-decoration: none;
      transition: all 0.3s ease;
      &:hover {
        color: var(--cl-white);
      }
    }
  }
  .cc-cp-foot-button .cc-cp-foot-save {
    position: relative;
    width: fit-content;
    z-index: 2;
    cursor: pointer;
    overflow: hidden;
    padding: 0 18px 0 60px;
    background: var(--cl-dark-blue);
    display: inline-flex;
    border: 2px solid var(--cl-white);
    border-radius: 24px;
    transition: .3s ease;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 8px;
    height: 48px;
    font-weight: 600;
    line-height: 1.2;
    margin-right: 18px;
    color: var(--cl-white);
    text-transform: capitalize !important;
    font-size: 16px;

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -1px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: 48px;
      height: 48px;
      border: 2px solid var(--cl-white);
      border-radius: 50%;
      background-color: var(--cl-white);
      transition: .3s ease;
      z-index: 4;
    }

    &:before {
      content: "";
      width: 16px;
      height: 16px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none'%3E%3Cpath stroke='%23E4B700' stroke-linejoin='bevel' stroke-width='3' d='M1 2.384h12.766V15.15m-12.22-1.193L13.155 2.351'/%3E%3C/svg%3E");
      transition: .3s ease;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 16px;
      z-index: 5;
    }

    &:focus {
      box-shadow: none !important;
      border: none !important;
    }
    &:hover {
      background-color: var(--cl-yellow);
      color: var(--cl-white);

      &:after {
        background-color: var(--cl-white);
      }

      &:before {
        transform: translateY(-50%) rotate(45deg);
      }
    }
    @media (max-width: 640px) {
      margin-bottom: 10px;
      margin-left: 10px;
    }
  }
}
.termsfeed-com---palette-dark .cc-cp-body-content-entry-text {
  a {
    display: inline-block;
    color: var(--cl-yellow);
    text-decoration: underline;
    text-decoration: var(--cl-blue);
    transition: all .3s ease;
    &:hover {
      color: var(--cl-white);
      text-decoration: var(--cl-white);
    }
  }
}
/*
Styles for mobile adaptation
*/

@media (max-width: 640px) {
  .termsfeed-com---nb-simple {
    max-width: 90%;
  }
  .termsfeed-com---nb .cc-nb-okagree,
  .termsfeed-com---nb .cc-nb-reject,
  .termsfeed-com---nb .cc-nb-changep {
    margin-top: 0 !important;
    max-width: 100%;
  }
  .termsfeed-com---nb .cc-nb-main-container {
    padding: 2rem;
  }
}

@media (max-width: 425px) {
  .termsfeed-com---nb .cc-nb-okagree,
  .termsfeed-com---nb .cc-nb-reject,
  .termsfeed-com---nb .cc-nb-changep {
    min-width: auto;
    text-align: center !important;
    justify-content: center;
    font-size: 16px;
  }
}

@media (max-width: 380px) {
  .termsfeed-com---nb-simple {
    max-width: 100%;
  }
}
