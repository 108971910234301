.blogs {
    margin-top: ac(160px, 74px);

    &::before {
        content: '';
        position: absolute;
        left: 50%;
        top: ac(-60px, -32px);
        transform: translateX(-50%);
        width: calc(100% - 40px);
        height: 2px;
        max-width: 1276px;
        background-color: var(--cl-tealish);
    }

    &__wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    &__title {
        width: calc(100% - 130px);
        max-width: 628px;
    }

    &__nav {
        margin-bottom: ac(-20px, 0px);
    }

    &__slider-box {
        margin-top: ac(40px, 32px);
        width: 100%;
        display: flex;
    }

    &__card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: 100%;

        &:hover {
            .blogs__name, .blogs__descr, .blogs__date {
                color: var(--cl-yellow);
            }
        }
    }

    &__img {
        width: 100%;
        @mixin aspect-ratio 412, 274;
    }

    &__name {
        padding-right: ac(15px, 0px);
        margin-bottom: 10px;
        margin-top: ac(21px, 10px);
        font-size: ac(25px, 20px);
        line-height: ac(39px, 29px);
        font-weight: 600;
        transition: .3s ease;

        @mixin max-sm {
            font-weight: 600;
            line-height: 140%;
        }
    }

    &__descr {
        margin-bottom: ac(15px, 10px);
        font-size: 18px;
        font-weight: 400;
        font-family: var(--font-sec);
        line-height: 25px;
        max-height: 75px;

        @mixin max-line-length 3;
        transition: .3s ease;
    }

    &__date {
        margin-top: auto;
        font-family: var(--font-sec);
        font-size: 16px;
        font-weight: 400;
        transition: .3s ease;
    }

    &__btn {
        margin: ac(50px, 40px) auto 0;
    }

    &--article {
        margin-top: ac(58px, 35px);
        padding: 0;
        
        &::before {
            display: none;
        }

        .btn {
            margin-top: auto;
            margin-left: 15px;
            height: ac(55px, 44px);
            border-radius: ac(28px, 24px);

            .arrow-btn {
                width: ac(55px, 44px);
                height: ac(55px, 44px);
            }

            &__text {
                line-height: ac(55px, 44px);
            }
        }

        .blogs {

            &__wrapper {
                padding: ac(60px, 35px) 0 ac(58px, 35px);
                border-top: 2px solid var(--cl-blue);
                border-bottom: 2px solid var(--cl-blue);
            }

            &__form {
                width: 100%;
                margin-top: ac(57px, 25px);
                display: flex;
                flex-wrap: wrap;
            }

            &__input-box {
                width: 100%;
                max-width: ac(628px, 400px);

                input {
                    line-height: ac(53px, 42px);
                    margin-top: ac(10px, 5px);
                    border: 1px solid var(--cl-blue);
    
                    &::placeholder {
                        font-weight: 400;
                        opacity: .5;
                    }
                }
            }

            &__label {
                font-size: ac(20px, 18px);
                font-weight: 600;
            }

            &__title {
                width: 100%;
            }

            &__blog-box {
                display: flex;
                flex-wrap: wrap;
                width: calc(100% + ac(20px, 10px));
                margin-top: ac(58px, 30px);
                margin-right: ac(-20px, -10px);
                margin-bottom: ac(-28px, -20px);
            }

            &__card {
                border-top: 2px solid var(--cl-tealish);
                border-bottom: 2px solid var(--cl-tealish);
                width: calc(100% / 3 - ac(20px, 10px));
                padding: ac(30px, 15px) 0 ac(27px, 15px);
                margin-right: ac(20px, 10px);
                margin-bottom: ac(28px, 20px);
                height: auto;
            }
        }
    }

    &--blogs {
        margin-top: ac(59px, 35px);
        
        &::before {
            display: none;
        }

        .blogs {
            &__blog-box {
                display: flex;
                flex-wrap: wrap;
                width: calc(100% + ac(20px, 10px));
                margin-right: ac(-20px, -10px);
                margin-bottom: ac(-28px, -20px);

                .job-results-title {
                    margin: 0 auto;
                }
            }

            &__card {
                border-top: 2px solid var(--cl-tealish);
                border-bottom: 2px solid var(--cl-tealish);
                width: calc(100% / 3 - ac(20px, 10px));
                padding: ac(30px, 15px) 0 ac(27px, 15px);
                margin-right: ac(20px, 10px);
                margin-bottom: ac(28px, 20px);
                height: auto;
            }

            &__btn {
                margin-top: ac(60px, 25px);

                .btn {
                    padding: 0 18px;
                    cursor: pointer;
                }

                .btn__text {
                    margin-right: 0;
                }
            }

            &__bottom {
                width: 100%;
                display: flex;
                align-items: flex-start;
                margin-top: ac(97px, 60px);
                padding: ac(61px, 30px) 0 ac(58px, 30px);
                border-top: 2px solid var(--cl-tealish);
                border-bottom: 2px solid var(--cl-tealish);
            }

            &__text-box {
                flex-shrink: 0;
                max-width: ac(800px, 400px);
                margin-right: ac(64px, 20px);

                h2 {
                    line-height: ac(39px, 30px);
                    margin-bottom: ac(19px, 15px);
                }

                p {
                    font-size: ac(22px, 18px);
                    line-height: ac(32px, 25px);
                    font-family: var(--font-sec);

                    &:not(:last-child) {
                        margin-bottom: 10px;
                    }
                }
            }

            &__col-content {
                max-width: 412px;
                max-height: 350px;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;

                .btn {
                    &:not(:last-child) {
                        margin-bottom: ac(30px, 24px);
                    }
                }

                .blogs__col {
                    width: 100%;
                }
            }

            &__col {
                max-width: 412px;
                max-height: 350px;
                width: 100%;
                padding: ac(30px, 20px) ac(30px, 20px) ac(45px, 20px);
                color: var(--cl-white);
                background-color: var(--cl-dark-blue);

                .simplebar-track.simplebar-vertical {
                    width: 5px;
                    background-color: rgb(228, 183, 0, .2);
                    border-radius: 0;
                }
        
                .simplebar-scrollbar::before {
                    border-radius: 0;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    background-color: var(--cl-yellow);
                    opacity: 1;
                }

                h2 {
                    line-height: ac(39px, 30px);
                    margin-bottom: ac(14px, 10px);
                }

                p {
                    font-size: 18px;
                    line-height: 25px;
                    font-family: var(--font-sec);

                    &:not(:last-child) {
                        margin-bottom: ac(20px, 15px);
                    }
                }

                a {
                    transition: .3s ease;
                    &:hover {
                        color: var(--cl-yellow);
                    }
                }
            }
        }
    }

    @mixin max-md {
        &--blogs,
        &--article {
            .blogs__card {
                width: calc(100% - ac(20px, 10px));
            }

            .blogs__img {
                /*height: ac(274px, 195px, 320, 768);*/
            
                /*img {
                    object-position: top;
                }*/
            }
        }

        &--blogs {
            .blogs__text-box {
                max-width: unset;
                margin-bottom: 20px;
            }

            .blogs__bottom {
                flex-direction: column;
            }

            .blogs__col {
                max-width: unset;
            }
        }
    }

    @mixin max-sm {
        &__name {
            font-size: 18px;
        }

        &__descr {
            font-size: 16px;
        }

        &--article {
            .btn {
                margin-top: 15px;
                margin-left: auto;
            }

            .blogs__input-box {
                max-width: unset;
            }
        }
    }
}