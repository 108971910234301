.popup-services {
    max-width: 845px;
    position: relative;
    padding: 0;

    #popup-services {
        max-height: 85vh;
        width: 100%;
        padding: 51px ac(60px, 10px, 320, 1440) ac(51px, 20px, 320, 1440) ac(61px, 10px, 320, 1440);

        .simplebar-track.simplebar-vertical {
            width: 6px;
            right: 0px;
        }

        .simplebar-scrollbar::before {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 1;
            border-radius: 0;
        }
    }


    &--contr {
        border-bottom: ac(10px, 5px) solid #159898;

        .simplebar-track.simplebar-vertical {
            background-color: rgba(21, 152, 152, 0.2);
        }

        .simplebar-scrollbar::before {
            background-color: #159898;
        }

        .popup-services__title, .popup-services__marked {
            color: #159898;
        }

        .popup-services__text-box {
            ul {
                li {
                    &::before {
                        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' fill='none'%3E%3Cpath fill='%23159898' d='m7.3 0 1.968 5.595L15 5.381l-4.515 3.672 1.971 5.596L7.7 11.328 3.188 15l1.569-5.729L0 5.947l5.728-.218L7.301 0Z'/%3E%3C/svg%3E") center / contain no-repeat;
                    }
                }
            }
        }
    }

    &--contin {
        border-bottom: ac(10px, 5px) solid #EA7159;

        .simplebar-track.simplebar-vertical {
            background-color: rgba(234, 113, 89, 0.2);
        }

        .simplebar-scrollbar::before {
            background-color: #EA7159;
        }

        .popup-services__title, .popup-services__marked {
            color: #EA7159;
        }

        .popup-services__text-box {
            ul {
                li {
                    &::before {
                        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' fill='none'%3E%3Cpath fill='%23EA7159' d='m7.3 0 1.968 5.595L15 5.381l-4.515 3.672 1.971 5.596L7.7 11.328 3.188 15l1.569-5.729L0 5.947l5.728-.218L7.301 0Z'/%3E%3C/svg%3E") center / contain no-repeat;
                    }
                }
            }
        }
    }

    &--prem {
        border-bottom: ac(10px, 5px) solid #B83C64;

        .simplebar-track.simplebar-vertical {
            background-color: rgba(184, 60, 100, 0.2);
        }

        .simplebar-scrollbar::before {
            background-color: #B83C64;
        }

        .popup-services__title, .popup-services__marked {
            color: #B83C64;
        }

        .popup-services__text-box {
            ul {
                li {
                    &::before {
                        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' fill='none'%3E%3Cpath fill='%23B83C64' d='m7.3 0 1.968 5.595L15 5.381l-4.515 3.672 1.971 5.596L7.7 11.328 3.188 15l1.569-5.729L0 5.947l5.728-.218L7.301 0Z'/%3E%3C/svg%3E") center / contain no-repeat;
                    }
                }
            }
        }
    }

    &--job {
        border-bottom: ac(10px, 5px) solid #8682FE;

        .simplebar-track.simplebar-vertical {
            background-color: rgba(134, 130, 254, 0.2);
        }

        .simplebar-scrollbar::before {
            background-color: #8682FE;
        }

        .popup-services__title, .popup-services__marked {
            color: #8682FE;
        }

        .popup-services__text-box {
            ul {
                li {
                    &::before {
                        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' fill='none'%3E%3Cpath fill='%238682FE' d='m7.3 0 1.968 5.595L15 5.381l-4.515 3.672 1.971 5.596L7.7 11.328 3.188 15l1.569-5.729L0 5.947l5.728-.218L7.301 0Z'/%3E%3C/svg%3E") center / contain no-repeat;
                    }
                }
            }
        }
    }

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
    }

    &__icon {
        margin-left: auto;
        width: ac(70px, 45px);
        height: ac(70px, 45px);

        img {
            object-fit: contain;
        }
    }

    &__title-box {
        width: calc(100% - 45px);
        display: flex;
        align-items: center;
        margin-bottom: ac(24px, 15px);
        @media (max-width: 768px) {
            width: 100%;
        }
    }

    &__title {
        width: calc(100% - ac(134px, 70px));
        margin-right: 20px;
        font-size: ac(40px, 26px);
        line-height: ac(55px, 32px);
        font-weight: 600;
    }

    &__text-box {
        width: 100%;

        p {
            font-size: ac(22px, 18px);
            line-height: ac(32px, 25px);
            font-family: var(--font-sec);
        }

        p, ul, li {
            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }

        ul {
            li {
                padding-left: ac(29px, 20px);
                position: relative;
                font-family: var(--font-sec);
                font-weight: 400;
                font-size: ac(18px, 16px);
                line-height: ac(25px, 22px);

                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: ac(4px, 2px);
                    width: 15px;
                    height: 15px;
                    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' fill='none'%3E%3Cpath fill='%23E4B700' d='m7.3 0 1.968 5.595L15 5.381l-4.515 3.672 1.971 5.596L7.7 11.328 3.188 15l1.569-5.729L0 5.947l5.728-.218L7.301 0Z'/%3E%3C/svg%3E") center / contain no-repeat;
                }
            }
        }
    }

    &__clients {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-top: ac(30px, 20px);
        margin-right: ac(-58px, -20px);
        margin-bottom: ac(-20px, -15px);

        &>* {
            margin-bottom: ac(20px, 15px);
            margin-right: ac(58px, 20px);
            max-width: 120px;
        }
    }

    &__marked {
        width: 100%;
        max-width: 615px;
        font-size: ac(22px, 18px);
        line-height: ac(32px, 25px);
        font-family: var(--font-sec);
        font-weight: 600;
    }

    &__logo {
        img {
            object-fit: contain;
            object-position: left center;
        }
    }

    &__btn-box {
        margin-top: ac(60px, 30px);
    }

    &__btn {
        align-items: center;
        cursor: pointer;

        .btn__text {
            font-size: ac(16px, 14px);
            line-height: 20px;
        }
    }

    &__link {
        font-size: ac(16px, 12px, 320, 1440);
        margin: ac(35px, 20px) 0 0 auto;
        font-weight: 600;
        transition: .3s ease;
        text-align: right;

        &:hover {
            color: var(--cl-yellow);
        }
    }

    &__close {
        position: absolute;
        right: ac(40px, 20px);
        top: ac(40px, 20px);
        width: 23px;
        height: 25px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='25' fill='none'%3E%3Cpath fill='%2304143A' d='M20.955 25c-.324 0-.632-.046-.924-.137-.26-.122-.519-.335-.778-.64l-8.508-9.735-1.653-1.508-8.46-9.644C.243 2.88.032 2.392 0 1.874 0 1.356.162.914.486.548.843.183 1.346 0 1.993 0c.713 0 1.28.229 1.702.686l8.265 9.506 1.264 1.051 9.14 10.512c.454.518.665 1.036.633 1.554-.033.488-.243.899-.632 1.234-.39.305-.86.457-1.41.457ZM2.188 25c-.616 0-1.102-.168-1.459-.503a1.83 1.83 0 0 1-.535-1.325c0-.518.211-1.02.633-1.508l8.508-10.01 2.43 3.2-7.973 9.46c-.421.457-.956.686-1.604.686Zm11.766-11.792-3.015-2.559L18.961.777c.26-.305.519-.503.778-.594.292-.122.616-.183.973-.183.648 0 1.134.198 1.458.594.357.366.519.823.486 1.371 0 .518-.21 1.021-.632 1.508l-8.07 9.735Z'/%3E%3C/svg%3E");
        cursor: pointer;
        transition: .3s ease;

        &:hover {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='25' fill='none'%3E%3Cpath fill='%23E4B700' d='M20.955 25c-.324 0-.632-.046-.924-.137-.26-.122-.519-.335-.778-.64l-8.508-9.735-1.653-1.508-8.46-9.644C.243 2.88.032 2.392 0 1.874 0 1.356.162.914.486.548.843.183 1.346 0 1.993 0c.713 0 1.28.229 1.702.686l8.265 9.506 1.264 1.051 9.14 10.512c.454.518.665 1.036.633 1.554-.033.488-.243.899-.632 1.234-.39.305-.86.457-1.41.457ZM2.188 25c-.616 0-1.102-.168-1.459-.503a1.83 1.83 0 0 1-.535-1.325c0-.518.211-1.02.633-1.508l8.508-10.01 2.43 3.2-7.973 9.46c-.421.457-.956.686-1.604.686Zm11.766-11.792-3.015-2.559L18.961.777c.26-.305.519-.503.778-.594.292-.122.616-.183.973-.183.648 0 1.134.198 1.458.594.357.366.519.823.486 1.371 0 .518-.21 1.021-.632 1.508l-8.07 9.735Z'/%3E%3C/svg%3E");
        }
    }

    @mixin max-md {
       
    }
}