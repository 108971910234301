.future {
    margin-top: ac(200px, 80px);
    background-color: var(--cl-dark-blue);

    &::before {
        content: '';
        position: absolute;
        left: 50%;
        top: ac(-100px, -40px);
        transform: translateX(-50%);
        width: calc(100% - 40px);
        height: 2px;
        max-width: 1276px;
        background-color: var(--cl-tealish);
    }

    &.smaller-margin-top {
        margin-top: ac(60px, 30px);

        &::before {
            display: none;
        }
    }

    &__wrapper {
        display: flex;
    }

    &__col {
        position: relative;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        max-width: ac(566px, 400px);
        margin-right:ac(82px, 25px);
        padding: ac(60px, 40px) 0;
    }

    &__text {
        color: var(--cl-white);

        h3 {
            &:not(.future__subtitle) {
                margin-top: ac(60px, 24px);
            }
        }

        p {
            margin-top: 10px;
            font-size: ac(22px, 16px);
            font-family: var(--font-sec);
            line-height: 145%;

            a {
                color: var(--cl-yellow);
                transition: .3s ease;
                @media (max-width: 640px) {
                    display: inline;
                }
                &:hover {
                    opacity: .5;
                }

            }

            @mixin max-sm {
                line-height: 162%;
            }
        }
    }

    &__title {
        margin-bottom: ac(17px, 12px);
        font-size: ac(40px, 24px);
    }

    &__subtitle {
        color: var(--cl-yellow);

        &.text-white {
            color: var(--cl-white);
            font-size: ac(28px, 20px);
            line-height: normal;
        }
    }

    &__img-box {
        position: relative;
        z-index: 1;

        &.with-absolute {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 48vw;

            .future__img {
                width: 55vw;
            }

            @media (min-width: 1600px) {
                left: 48vw;
            }

            @media (min-width: 1900px) {
                left: 48vw;
            }

            @media (max-width: 1300px) {
                left: 45vw;
            }

            @media (max-width: 1080px) {
              left: 48vw;
            }
        }
    }

    &__img {
        position: absolute;
        left: 0;
        top: 0;
        width: 50vw;
        height: 100%;
        z-index: -1;

        img {
            object-position: right bottom;
        }

        &.object-center {
            img {
                object-position: 65% 50% !important;
            }

        }
    }

    &__btn {
        margin-top: ac(40px, 25px);
    }

    &__buttons {
        margin-top: ac(40px, 25px);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: ac(20px, 10px)
    }

    &__row {
        position: relative;
        border-bottom: 2px solid var(--cl-white);
        padding: ac(39px, 10px) 0 ac(39px, 15px) ac(38px, 30px);

        &.content-element {
            h3, h4 {
                margin-top: 0 !important;

                &:not(:last-child) {
                    margin-bottom: 10px;
                }
            }

            &::before {
                top: ac(45px, 12px);
            }
        }

        &, p {
            font-family: var(--font-main);
            margin-top: 0;
            font-weight: 600;
            font-size: ac(18px, 16px);
            line-height: ac(25px, 22px);
        }

        &:last-of-type {
            border-bottom: 0;
            padding-bottom: ac(30px, 10px);
        }

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: ac(40px, 10px);
            width: ac(22px, 18px);
            height: ac(22px, 18px);
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22' fill='none'%3E%3Cpath fill='%23E4B700' d='m10.708 0 2.886 8.207L22 7.892l-6.622 5.386 2.891 8.207-6.977-4.871L4.676 22l2.3-8.402L0 8.722l8.401-.32L10.708 0Z'/%3E%3C/svg%3E") center / contain no-repeat;
        }

        ul {
            li {
                &:before {
                    background-color: var(--cl-white) !important;
                }
            }
        }
    }

    &--services {
        .future__title {
            margin-bottom: 10px;
        }

        .future__img {
            img {
                object-position: right top;
            }

            &.object-left {
                img {
                    object-position: left top;
                }
            }
        }
    }

    &--why {
        margin-top: ac(100px, 60px);

        &::before {
           display: none;
        }

        .future__title {
            margin-bottom: ac(37px, 20px);
        }

        .future__img {
            img {
                object-position: right top;
            }
        }

        .future__col {
            max-width: ac(566px, 340px);
        }
    }

    @mixin xxxl {
        &--why {
            .future__wrapper {
                min-height: 760px;
            }

            .future__col {
                max-width: 600px;
                justify-content: center;
            }
        }
    }

    @mixin max-lg {
        &:not(.future--why, .future--services) {
            .future__img {
                img {
                    object-position: 50% top;
                }
            }
        }
    }

    @mixin media 900 {
        &:not(.future--why, .future--services) {
            /*margin-top: 0;*/

            &::before {
                display: none;
            }
        }

        &__wrapper {
            flex-direction: column;
        }
        
        &__col {
            margin-right: 0;
            max-width: unset;
        }

        &--why {
            .future__col {
                max-width: unset;
            }
        }

        &__text {
            &>*:last-child {
                padding-bottom: 0px;
            }
        }

        &__img-box {
           display: none;

        }

        &__btn {
            margin-right: auto;
            margin-left: auto;
        }
    }

    &.sap-cv-page {
        .future__text-descr {
            font-size: ac(20px, 18px);
            font-weight: 500;

            span {
                background: var(--cl-yellow);
                color: var(--cl-dark-blue);
            }
        }
        
        @media (max-width: 900px) {
            .future__img-box {
                display: block;

            }

            .future__img {
                @mixin aspect-ratio 1.2, 1;
                width: calc(100% + 40px);
                margin-left: -20px;
            }
          
        }


    }
}