input, textarea {
    width: 100%;
    padding: 0px 30px;
    border-radius: 28px;
    background-color: var(--cl-white);
    @mixin transparent-bg-input var(--cl-tealish);

    &,
    &::placeholder {
        color: var(--cl-tealish);
        font-family: var(--font-sec);
        font-size: ac(22px, 16px);
        font-weight: 400;
        line-height: 55px;

        @mixin max-sm {
            font-weight: 700;
            line-height: 48px;
        }
    }

    &::placeholder {
        opacity: .7;
    }
}

textarea{
    padding: 15px 30px;
    height: 110px;
    resize: none;
    scrollbar-width: thin;
    scrollbar-color: var(--cl-yellow) rgba(5, 21, 59, .2);
    overflow-x: hidden;
    overflow-y: auto;
    clip-path: inset(0 0 0 0 round 28px);
    @mixin transparent-bg-input var(--cl-tealish);

    &,&::placeholder {
        line-height: ac(25px, 20px);
    }

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        background: rgba(5, 21, 59, .2);
        border-radius: 0;
    }

    &::-webkit-scrollbar-thumb {
        background: var(--cl-yellow);
        border-radius: 0;
    }
}


.choices {
    flex-grow: 1;
    margin-bottom: 0;
    border-radius: 28px;

    &::after {
        display: none;
    }

    &::before {
        content: '';
        position: absolute;
        top: ac(22px, 18px, 639, 640);
        right: ac(28px, 15px);
        width: 21px;
        height: 12px;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='12' fill='none'%3E%3Cpath stroke='%23061654' stroke-linejoin='bevel' stroke-width='3' d='m2 2 8.5 8.5L19 2'/%3E%3C/svg%3E") center / contain no-repeat;
        transition: .3s ease;
    }

    &.is-open {
        &::before {
            transform: rotate(-180deg);
        }
    }

    &__inner {
        padding: 0 50px 0 30px !important;
        border-radius: 28px !important;
    }
    
    .choices__list {
        width: 100%;
        overflow: hidden !important;
        display: flex;
        flex-direction: column;

        &--single {
            .choices__item {
                color: var(--cl-tealish);
                font-family: var(--font-sec);
                font-size: ac(22px, 16px);
                font-weight: 400;
                line-height: 55px;

                @mixin max-line-length-one;
        
                @mixin max-sm {
                    font-weight: 700;
                    line-height: 48px;
                }
            }
        }

        &--dropdown {
            display: none;
            border-radius: 28px !important;
            z-index: 200;

            &.is-active {
                display: flex;
            }

            .choices__item--selectable, .choices__list[aria-expanded] .choices__item--selectable {
                padding-right: 10px;
            }

            .choices__item, .choices__input {
                line-height: 30px;
                word-break: break-word;
            }

            .choices__item {
                &::after {
                    display: none;
                }
            }
        }

        & > div {
            padding-right: 20px;
            width: calc(100% + 20px);
            max-height: 301px;
        }

        .simplebar-track.simplebar-vertical {
            width: 9px;
            right: 20px;
        }

        .simplebar-scrollbar::before {
            width: 5px;
            background-color: var(--cl-yellow);
            opacity: 1;
        }

    }

    .choices__inner,
    .choices__list--single {
        padding: 0;
        padding-bottom: 0 !important;
    }

    .choices__inner {
        background-color: var(--cl-white);
        border: 0;
    }

    .choices__item--selectable {
        font-size: 15px;
        font-weight: 300;
        color: var(--cl-tealish);
        line-height: 48px;
        opacity: 1;
    }
}

.daterangepicker {
    z-index: 10000 !important;
    margin-top: -17px !important;

    .drp-buttons .btn {
        padding: 0 8px;
        max-width: 100px;
        margin-top: 0;
        margin-bottom: 0;
        padding: 0px 15px;
        border-radius: 5px;
        line-height: 34px;
        border: 1px solid var(--cl-tealish);

        &:hover {
            background-color: var(--cl-tealish);
            color: var(--cl-white);
        }
    }
    td.active,
    .daterangepicker td.active:hover {
        background-color: var(--cl-tealish) !important;
    }

    th select {
        scrollbar-width: thin;
        scrollbar-color: transparent var(--cl-tealish);

        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
            border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb {
            background: var(--cl-tealish);
            border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: var(--cl-tealish);
        }
    }
}

.category-filter {
    &__text {
        font-family: var(--font-main);
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: ac(15px, 14px);
        padding-right: ac(15px, 14px);
        padding-top: 5px;
        padding-bottom: 5px;
        border: 1px solid var(--cl-blue);
        border-radius: 60px;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        cursor: pointer;
        background: transparent;
        transition: background-color .3s ease, color .3s ease;
    }

    &:hover {
        .category-filter__text {
            background-color: var(--cl-light-blue);
        }
    }

    &__input {
        display: none;

        &:checked {
            + .category-filter__text {
                background-color: var(--cl-blue);
                color: var(--cl-yellow);
            }
        }
    }



}

.fix-flipped-choice {
    .choices {
        .choices__list {
            &.choices__list--dropdown {
                &.is-active {
                    top: auto !important;
                    bottom: 100% !important;
                    margin-top: 0 !important;
                    margin-bottom: -1px !important;
                }
            }
        }
    }
}