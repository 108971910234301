
h1 {
  font-size: ac(60px, 32px);
  font-weight: 600;
  line-height: normal;

  @mixin max-sm {
    font-weight: 700;
    line-height: 150%;
  }
}

h2 {
  font-size: ac(40px, 26px);
  line-height: 137.5%;
  font-weight: 600;

  @mixin max-sm {
    font-weight: 700;
    line-height: 168%;
  }

  &.sm {
    font-size: ac(25px, 20px);
    line-height: 140%;
    font-weight: 600;

    @mixin max-sm {
      line-height: 160%;
    }
  }
}

h3 {
  font-size: ac(30px, 22px);
  font-weight: 600;
  line-height: 144%;

  @mixin max-sm {
    font-family: var(--font-sec);
  }
}

a {
  display: inline-block;
}

.content-element {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
  }

  h4 {
    color: var(--cl-medium-grey);
    font-weight: 500;

    &:not(:last-child) {
      margin-bottom: ac(20px, 16px);
    }
  }

  p {
    font-family: var(--font-sec);
    font-weight: 400;
    font-size: ac(18px, 16px);
    line-height: ac(25px, 22px);

    a {
      color: var(--cl-yellow);
      transition: .3s ease;

      &:hover {
        opacity: .5;
      }

    }
  }

  ol,
  ul {
    li {
      list-style: inside;
    }
  }

  ol {
    list-style: decimal;
  }

  ul {
    list-style: none;

    li {
      list-style: none;
      position: relative;
      padding-left: 15px;

      &:before {
        content: '';
        position: absolute;
        left: 0px;
        top: 8px;
        width: 6px;
        height: 6px;
        background-color: var(--cl-tealish);
        border-radius: 100%;
      }
    }
  }

  li,
  p {
    &:not(:last-child) {
      margin-bottom: ac(10px, 5px);
    }
  }
}
