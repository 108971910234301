.popup-team {
    max-width: 844px;
    padding: 0;
    position: relative;

    #popup-team {
        width: 100%;
        max-height: 85vh;
        padding: 60px ac(60px, 10px, 320, 1440) ac(60px, 20px, 320, 1440);

        .simplebar-track.simplebar-vertical {
            width: 6px;
            right: 0px;
            background-color: rgba(228, 183, 0, .2);

        }

        .simplebar-scrollbar::before {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: var(--cl-yellow);
            opacity: 1;
            border-radius: 0;
        }
    }

    &__wrapper {
        display: flex;

        .team__card {
            align-self: flex-start;
            margin-bottom: 0;
            margin-right: ac(32px, 15px);
            flex-shrink: 0;
            width: 100%;
            max-width: ac(264px, 240px);

            .team__img {
                height: ac(264px, 220px);
            }

            .team__bottom {
                padding: 15px 19px;
            }

            .team__name {
                padding-bottom: 4px;
                margin-bottom: 7px;
            }

            .team__pos {
                font-size: ac(18px, 16px);
                margin-bottom: 2px;
            }

            .team__link {
                font-size: 14px;
                line-height: 20px;

                &:not(:last-child) {
                    margin-bottom: 1px;
                }

                span {
                    width: 12px;
                    margin-right: 6px;
                }
            }

            .team__link--msn {
                span {
                    height: 8px;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11' height='8' fill='none'%3E%3Cpath fill='%23061654' d='M5.5 4.317.007 0h10.986L5.5 4.317Zm0 1.144L0 1.139V8h11V1.139L5.5 5.46Z'/%3E%3C/svg%3E");
                }

                &:hover {
                    span {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11' height='8' fill='none'%3E%3Cpath fill='%23E4B700' d='M5.5 4.317.007 0h10.986L5.5 4.317Zm0 1.144L0 1.139V8h11V1.139L5.5 5.46Z'/%3E%3C/svg%3E");
                    }
                }
            }

            .team__link--tel {
                span {
                    height: 12px;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none'%3E%3Cpath fill='%23061654' d='M10.244 7.724A6.185 6.185 0 0 1 8.3 7.415c-.304-.104-.677-.009-.863.182l-1.225.925c-1.42-.759-2.295-1.633-3.043-3.043l.897-1.193a.88.88 0 0 0 .217-.892 6.194 6.194 0 0 1-.31-1.946.875.875 0 0 0-.875-.874h-2a.875.875 0 0 0-.874.874c0 5.525 4.494 10.02 10.019 10.02a.875.875 0 0 0 .874-.875V8.598a.875.875 0 0 0-.874-.874Z'/%3E%3C/svg%3E");
                }

                &:hover {
                    span {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none'%3E%3Cpath fill='%23E4B700' d='M10.244 7.724A6.185 6.185 0 0 1 8.3 7.415c-.304-.104-.677-.009-.863.182l-1.225.925c-1.42-.759-2.295-1.633-3.043-3.043l.897-1.193a.88.88 0 0 0 .217-.892 6.194 6.194 0 0 1-.31-1.946.875.875 0 0 0-.875-.874h-2a.875.875 0 0 0-.874.874c0 5.525 4.494 10.02 10.019 10.02a.875.875 0 0 0 .874-.875V8.598a.875.875 0 0 0-.874-.874Z'/%3E%3C/svg%3E");
                    }
                }
            }
        }
    }

    &__title {
        max-width: 380px;
        line-height: ac(42px, 30px);
        font-size: ac(30px, 22px);
        font-weight: 600;
        margin-bottom: ac(20px, 15px);
    }

    &__row {
        ul {
            li {
                &:not(:last-child) {
                    margin-bottom: ac(20px, 10px);
                }
                padding-left: ac(29px, 20px);
                position: relative;
                font-family: var(--font-sec);
                font-weight: 400;
                font-size: ac(18px, 16px);
                line-height: ac(25px, 22px);

                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 4px;
                    width: 15px;
                    height: 15px;
                    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' fill='none'%3E%3Cpath fill='%23E4B700' d='m7.3 0 1.968 5.595L15 5.381l-4.515 3.672 1.971 5.596L7.7 11.328 3.188 15l1.569-5.729L0 5.947l5.728-.218L7.301 0Z'/%3E%3C/svg%3E") center / contain no-repeat;
                }
            }
        }
    }

    &__close {
        position: absolute;
        right: 20px;
        top: 20px;
        width: 23px;
        height: 25px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='25' fill='none'%3E%3Cpath fill='%2304143A' d='M20.955 25c-.324 0-.632-.046-.924-.137-.26-.122-.519-.335-.778-.64l-8.508-9.735-1.653-1.508-8.46-9.644C.243 2.88.032 2.392 0 1.874 0 1.356.162.914.486.548.843.183 1.346 0 1.993 0c.713 0 1.28.229 1.702.686l8.265 9.506 1.264 1.051 9.14 10.512c.454.518.665 1.036.633 1.554-.033.488-.243.899-.632 1.234-.39.305-.86.457-1.41.457ZM2.188 25c-.616 0-1.102-.168-1.459-.503a1.83 1.83 0 0 1-.535-1.325c0-.518.211-1.02.633-1.508l8.508-10.01 2.43 3.2-7.973 9.46c-.421.457-.956.686-1.604.686Zm11.766-11.792-3.015-2.559L18.961.777c.26-.305.519-.503.778-.594.292-.122.616-.183.973-.183.648 0 1.134.198 1.458.594.357.366.519.823.486 1.371 0 .518-.21 1.021-.632 1.508l-8.07 9.735Z'/%3E%3C/svg%3E");
        cursor: pointer;
        transition: .3s ease;

        &:hover {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='25' fill='none'%3E%3Cpath fill='%23E4B700' d='M20.955 25c-.324 0-.632-.046-.924-.137-.26-.122-.519-.335-.778-.64l-8.508-9.735-1.653-1.508-8.46-9.644C.243 2.88.032 2.392 0 1.874 0 1.356.162.914.486.548.843.183 1.346 0 1.993 0c.713 0 1.28.229 1.702.686l8.265 9.506 1.264 1.051 9.14 10.512c.454.518.665 1.036.633 1.554-.033.488-.243.899-.632 1.234-.39.305-.86.457-1.41.457ZM2.188 25c-.616 0-1.102-.168-1.459-.503a1.83 1.83 0 0 1-.535-1.325c0-.518.211-1.02.633-1.508l8.508-10.01 2.43 3.2-7.973 9.46c-.421.457-.956.686-1.604.686Zm11.766-11.792-3.015-2.559L18.961.777c.26-.305.519-.503.778-.594.292-.122.616-.183.973-.183.648 0 1.134.198 1.458.594.357.366.519.823.486 1.371 0 .518-.21 1.021-.632 1.508l-8.07 9.735Z'/%3E%3C/svg%3E");
        }
    }

    @mixin max-md {
        &__wrapper {
            flex-direction: column;

            .team__card {
                margin: 0 auto;
            }
        }

        &__text-box {
            margin-top: 20px;
        }

        &__title {
            max-width: unset;
        }
    }
}