.hero {
    display: flex;
    flex-direction: column;
    height: calc(var(--vh) * 100);
    min-height: 400px;
    max-height: 900px;
    padding-top: ac(250px, 105px, 375, 1440);
    background: radial-gradient(145.96% 57.01% at 69.51% 37.17%, #061654 0%, #04143A 100%);
    z-index: 1;
    transition: .3s ease;
    overflow: hidden;

    &.hero-maintenance {
        /*margin-bottom: ac(-100px, -60px);*/
        max-height: 100vh;
        min-height: 400px;

        > .hero__maintenance-info {
            background: transparent;
            width: auto;
            left: 50%;
            transform: translateX(-50%);
            top: ac(60px, 20px);
            position: absolute;
            max-width: unset;
            z-index: 10;

            @media (min-width: 1023px) {
              white-space: nowrap;
            }

            @media (max-width: 1022px) {
                left: 20px;
                right: 20px;
                transform: none;
            }

            @media (max-width: 640px) {
                top: 16px;
            }

            .hero__maintenance-info-text {
                h3 {
                    font-size: ac(28px, 16px);
                    font-weight: 700;
                    font-family: var(--font-sec);
                }
            }
        }
    }
    
    &__decor, &__img {
        position: absolute;
        width: 100%;

        img {
            object-fit: contain;
            object-position: left bottom;
        }
    }

    &__decor {
        left: calc(50% - ac(100px, 200px)) !important;
        bottom: ac(-215px, 0px, 375, 768) !important;
        max-width: ac(686px, 500px, 375, 768);
        height: ac(1077px, 575px, 375, 768);
        opacity: .05 !important;
        z-index: -1;
        transform: translateX(calc(-50% + ac(225px, -33px, 375, 768)));
    }

    &__img {
        left: 50%;
        bottom: ac(0px, 190px, 767, 768);
        max-width: ac(836px, 358px, 375, 768);
        height: ac(775px, 335px, 375, 768);
        max-height: 90vh;
        transform: translateX(calc(-50% + ac(337px, 22px)));

        opacity: 0;
        visibility: hidden;

        &.active {
            opacity: 1;
            visibility: visible;
        }

        img {
            transition: .3s ease;
            transform: scaleX(-1);
        }

        &:nth-child(2) {
            img {
                transform: scaleX(1);
                object-position: right bottom;
            }
        }

        &.not-rotate-img {
            img {
                transform: scaleX(1);
                object-position: right bottom;
            }
        }

        &.hero-maintenance-img {
            visibility: visible;
            opacity: 1;
            
            img {
                transform: scaleX(1);
            }
            
            @media (max-width: 491px) {
              bottom: auto;
                top: 100px;
            }

            @media (max-width: 425px) {
                top: 200px;
                left: 30% !important;
            }

            &.not-rotate {
                height: ac(550px, 335px, 375, 768) !important;
                img {
                    transform: scaleX(-1);
                }
            }
        }
    }

    &__title-box {
        position: relative;
        width: 100%;
        max-width: ac(628px, 400px);
        z-index: 1;
        padding-bottom: 20px;
    }

    &__title {
        color: var(--cl-white);
    }

    &__subtitle {
        margin-top: ac(40px, 20px);
        color: var(--cl-white);
        font-family: var(--font-sec);
        font-size: ac(22px, 16px);
        font-weight: 700;
        line-height: normal;

        span {
            color: var(--cl-yellow);
            text-shadow: 0 0 0px rgba(228, 182, 0, 0);
            animation: textAnim 5s 1s both ease-in-out infinite;
        }

        @keyframes textAnim {
            0%, 100% {
                color: rgba(228, 182, 0, 0.8); 
                text-shadow: 0 0 0px rgba(228, 182, 0, 0);
            }
            50% {
                color: rgba(228, 182, 0, 1); 
                text-shadow: 0 0 20px rgba(228, 182, 0, 1);
            }
        }
    }

    &__buttons {
        margin-top: ac(40px, 25px);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: ac(20px, 10px);
    }

    &__bottom {
        padding: ac(38px, 28px) 0 ac(38px, 40px);
        margin-top: auto;
        background: rgba(255, 255, 255, 0.30);
        backdrop-filter: blur(15px);

        .cont {
            display: flex;
            align-items: flex-start;
        }
    }

    &__footer-box {
        position: relative;
        width: 100%;
        max-width: ac(640px, 500px);
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-column-gap: ac(30px, 16px);
        grid-row-gap: ac(30px, 16px);

        @media (max-width: 768px) {
            margin-bottom: 20px;
            max-width: 100%;

        }

        @media (max-width: 491px) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
            grid-row-gap: 0;
        }
    }

    &__footer-item {
        background: var(--cl-tealish);
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
        gap: ac(20px, 16px);
        padding: ac(10px, 8px) ac(20px, 10px);

        .footer__contact {
            margin: 0 !important;
            padding-bottom: 10px;

            &:last-child {
                border-bottom: none;
                padding-bottom: 0;
            }
        }

        .footer__suptitle {
            font-size: ac(18px, 16px);
        }

        .footer__link {
            font-size: ac(20px, 20px);
        }

        &:first-child {
            &:not(:last-child) {
                @media (max-width: 491px) {
                    .footer__contact {
                        &:last-child {
                            padding-bottom: 10px;
                            border-bottom: 2px solid var(--cl-yellow);
                        }
                    }
                }
            }
        }
    }

    &__maintenance-info {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        background: #505B7C;
        gap: 5px;
        margin-left: 10px;
        margin-right: 10px;

        @media (max-width: 768px) {
            margin-left: 0;
            margin-right: 0;
            border: 1px solid var(--cl-dark-blue);
            margin-bottom: 10px;
        }
    }

    &__maintenance-info-icon {
        --size: ac(34px, 26px);
        width: var(--size);
        height: var(--size);

        svg, img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__maintenance-info-text {
        color: #E9EBEF;
        font-weight: 600;
        flex: 1;
        text-align: center;
    }

    &__btn-social {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        background: var(--cl-tealish);
        transition: .3s;

        .footer__soc {
            margin: 0 !important;
        }

        &:hover {
            background-color: var(--cl-dark-blue);
        }

        @media (max-width: 491px) {
            margin-bottom: 10px;
        }
    }

    &__slider-box {
        position: relative;
        display: flex;
        flex-shrink: 0;
        width: 100%;
        max-width: 520px;
        margin-right: 20px;
    }

    &__slider {
        overflow: hidden !important;
        min-height: ac(94px, 115px);
    }

    &__slide {
        opacity: 0;
        transition: opacity .3s linear;
        &.swiper-slide-active {
            opacity: 1;
            transition: opacity .5s linear;
        }
    }

    &__card {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    &__simplebar {
        width: 100%;
        max-height: calc(28px * 3);
        margin-bottom: auto;

        .simplebar-track {
			width: 6px;
			background-color: rgb(8, 2, 35, .2);
			border-radius: 0;
		}

		.simplebar-scrollbar::before {
			background-color: var(--cl-yellow);
			border-radius: 0;
			opacity: 1;
			top: 0 !important;
			left: 0;
			bottom: 0 !important;
			right: 0;
		}
    }

    &__descr {
        padding-right: 10px;
        margin-bottom: 3px;

        &>* {
            font-size: ac(22px, 18px);
            font-weight: 600;
            line-height: 27px;
            font-family: var(--font-sec);
            color: var(--cl-white);
        }
    }

    &__pos {
        margin-top: 11px;
        margin-left: ac(71px, 82px);
        font-family: var(--font-sec);
        font-size: ac(17px, 14px);
        font-weight: 600;
        line-height: normal;
        color: var(--cl-yellow);
        
        @mixin max-sm {
            font-weight: 700;
            line-height: 171%;
        }
    }

    &__nav {
        position: absolute;
        bottom: 0;
        left: ac(-5px, 0px);

        .slider-nav__btn {
            width: 25px;
            height: 25px;
            background-color: transparent;
            border-color: transparent;

            &:first-child {
                margin-right: 10px;
            }

            &:hover {
                .slider-nav__icon {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='17' fill='none'%3E%3Cpath stroke='%23E4B700' stroke-linejoin='bevel' stroke-width='2' d='m8.143 1.536-7.07 7.071 7.07 7.071m6.108-7.428H1.394'/%3E%3C/svg%3E");
        
                    &--next {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='17' fill='none'%3E%3Cpath stroke='%23E4B700' stroke-linejoin='bevel' stroke-width='2' d='m7.071 1.536 7.072 7.071-7.072 7.071M.964 8.25h12.857'/%3E%3C/svg%3E");
                    }
                }
            }
        }

        .slider-nav__icon {
            width: 15px;
            height: 17px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='17' fill='none'%3E%3Cpath stroke='%23fff' stroke-linejoin='bevel' stroke-width='2' d='m8.143 1.536-7.07 7.071 7.07 7.071m6.108-7.428H1.394'/%3E%3C/svg%3E");

            &--next {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='17' fill='none'%3E%3Cpath stroke='%23fff' stroke-linejoin='bevel' stroke-width='2' d='m7.071 1.536 7.072 7.071-7.072 7.071M.964 8.25h12.857'/%3E%3C/svg%3E");
            }
        }
    }

    &__btn-box {
        margin-left: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        margin-right: ac(-50px, -28px);
        margin-top: -10px;
    }

    &:not(.hero--jobs, .hero--talent-filter) {
        .hero__btn {
            display: inline-flex;
            margin-right: ac(50px, 28px);
            margin-top: 10px;
    
            .arrow-btn {
                width: ac(88px, 48px);
                height: ac(88px, 48px);
    
                &__icon {
                    width: ac(28px, 24px);
                    height: ac(28px, 24px);
                }
            }
    
            &:hover {
                .arrow-btn {
                    background-color: var(--cl-white);
                    transform: rotate(45deg);
                }
            }
        }
    }

    &__btn-text {
        margin-right: ac(20px, 13px);
        line-height: ac(88px, 48px);
        font-size: ac(25px, 18px);
        font-weight: 600;
        transition: .3s ease;

        @mixin max-sm {
            font-weight: 700;
        }
    }

    &--referral {
        height: auto;
        padding-top: ac(239px, 140px);
        background: transparent;
        overflow: visible;

        .hero {
            &__decor {
                left: calc(50% - ac(-175px, 100px, 320, 1440)) !important;
                bottom: ac(-100px, 220px, 320, 1440) !important;
                max-width: unset;
                width: ac(545px, 230px, 320, 1440);
                height: ac(548px, 230px, 320, 1440);
                opacity: 1 !important;
            }

            &__wrapper {
                display: flex;
            }

            &__title-box {
                flex-shrink: 0;
                padding-bottom: 0;
                margin-right: 20px;
                width: 100%;
                max-width: ac(737px, 350px);
            }
            
            &__title, &__subtitle, &__descr {
                color: var(--cl-dark-blue);
            }

            &__title {
                margin-bottom: ac(35px, 20px);
                padding-bottom: 0px;
            }

            &__subtitle {
                margin-top: 0;
                font-family: var(--font-main);
                font-size: ac(25px, 20px);
                line-height: ac(39px, 30px);
                font-weight: 600;

                &:not(:last-child) {
                    margin-bottom: ac(15px, 10px);
                }
            }

            &__col {
                margin-top: ac(59px, 25px);
            }

            &__descr {
                position: relative;
                border-top: 2px solid var(--cl-tealish);
                border-bottom: 2px solid var(--cl-tealish);
                padding: ac(40px, 20px) 16px;
                font-weight: 400;
                font-size: ac(22px, 18px);
                line-height: ac(32px, 24px);
                font-family: var(--font-sec);

                span {
                    position: relative;

                    &::after {
                        content: '';
                        position: absolute;
                        bottom: ac(9px, 6px);
                        right: -18px;
                        width: 15px;
                        height: 15px;
                        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' fill='none'%3E%3Cpath fill='%23E4B700' d='m7.3 0 1.968 5.595L15 5.381l-4.515 3.672 1.971 5.596L7.7 11.328 3.188 15l1.569-5.729L0 5.947l5.728-.218L7.301 0Z'/%3E%3C/svg%3E") center / contain no-repeat;
                    }
                }

                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: ac(47px, 24px);
                    width: 15px;
                    height: 15px;
                    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' fill='none'%3E%3Cpath fill='%23E4B700' d='m7.3 0 1.968 5.595L15 5.381l-4.515 3.672 1.971 5.596L7.7 11.328 3.188 15l1.569-5.729L0 5.947l5.728-.218L7.301 0Z'/%3E%3C/svg%3E") center / contain no-repeat;
                }
            }

            &__link {
                margin-top: ac(15px, 10px);
                font-family: var(--font-sec);
                transition: .3s ease;

                &:hover {
                    color: var(--cl-yellow);
                }
            }
        }
    }

    &--talent-filter,
    &--jobs {
        height: auto;
        padding-top: ac(231px, 140px);
        padding-bottom: 70px;
        background: var(--cl-white);
        overflow: visible;

        .hero {
            &__clear-btn {
                right: -20px;
                bottom: 50%;
                transform: translate(100%, 50%);
                background-color: #fff;

                &:hover {
                    background-color: var(--cl-tealish);
                }

                .btn__text {
                    white-space: nowrap;
                }
            }

            &__decor {
                left: calc(50% - ac(-175px, 100px, 320, 1440)) !important;
                bottom: ac(55px, 220px, 320, 1440) !important;
                max-width: unset;
                width: ac(545px, 230px, 320, 1440);
                height: ac(548px, 230px, 320, 1440);
                opacity: 1 !important;
            }

            &__img {
                max-height: 90%;
                left: 50%;
                bottom: 0;
                transform: translateX(calc(-50% + ac(435px, 200px, 768, 1440)));
                max-width: ac(470px, 220px, 768, 1440);
                height: ac(590px, 305px, 768, 1440);
                opacity: 1;
                visibility: visible;

                img {
                    object-position: right bottom;
                    transform: scaleX(1);
                }

                @media only screen and (max-height: 540px) and (min-width: 785px) {
                    display: none;
                }
            }
            
            &__title {
                padding-bottom: 0px;
                color: var(--cl-tealish);
            }

            &__form {
                position: relative;
                display: flex;
                align-items: flex-end;
                flex-wrap: wrap;
                max-width: ac(920px, 445px);
                width: 100%;
                margin-top: ac(86px, 35px);
                margin-right: ac(-20px, -15px);
                margin-bottom: ac(-28px, -15px);

                &>* {
                    margin-right: ac(20px, 15px);
                    margin-bottom: ac(28px, 15px);
                }

                &__submit-box {
                    margin-top: 15px;
                    transform: translateY(ac(-2px, 0px));
                }

                input:not(.choices__input) {
                    line-height: ac(53px, 42px);
                    padding-left: ac(30px, 15px);
                    border: 1px solid var(--cl-dark-blue);

                    &::placeholder {
                        font-weight: 400;
                        opacity: .5;
                    }
                }
            }

            &__form--anonymous {
                max-width: ac(1000px, 445px);

                .hero__submit-box {
                    @media (max-width: 640px) {
                        order: 5 !important;
                    }
                }
            }

            &__label {
                margin-bottom: ac(10px, 5px);
                font-size: ac(20px, 18px);
                font-weight: 600;
            }

            &__input-box {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                flex-shrink: 0;

                &:not(.hero__input-box--select) {
                    width: ac(412px, 200px);
                }

                &--select {
                    width: ac(304px, 200px);

                    .choices {
                        width: 100%;

                        &::before {
                          top: ac(23px, 17px);
                        }

                        .choices__inner {
                          border: 1px solid var(--cl-dark-blue);
                          padding-left: ac(30px, 15px) !important;
                        }

                        .choices__list {
                            &--single {
                                .choices__item {
                                    line-height: ac(53px, 42px);
                                }
                            }
                        }
                    }
                }
            }

            
        }
    }

    &--talent-filter {
        padding-bottom: ac(80px, 70px);

        .hero {
            &__img {
                max-height: 84%;
                transform: translateX(calc(-50% + ac(429px, 200px, 768, 1440)));
            }

            &__form {
                max-width: ac(940px, 445px);
            }

            &__form--anonymous {
                max-width: ac(1000px, 445px);

                .hero__submit-box {
                    @media (max-width: 640px) {
                        order: 5 !important;
                    }
                }
            }
        }
    }

    &--blogs {
        min-height: unset;
        height: auto;
        padding-top: ac(231px, 140px);
        background: var(--cl-white);
        overflow: visible;


        .choices__list--dropdown .choices__item--selectable[data-select-text], .choices__list[aria-expanded] .choices__item--selectable[data-select-text] {
            padding-right: 16px !important;
        }

        .simplebar-track {
            display: none !important;
        }

        .hero__input-box:not(.hero__input-box--select) {
            max-width: ac(628px, 300px);
            width: 100%;
        }

        .btn {
            margin-left: 15px;
            margin-right: auto;
            height: ac(55px, 44px);
            border-radius: ac(28px, 24px);

            .arrow-btn {
                width: ac(55px, 44px);
                height: ac(55px, 44px);
            }

            &__text {
                line-height: ac(55px, 44px);
            }
        }

        .hero {
            &__decor {
                left: calc(50% - ac(-200px, -70px)) !important;
                bottom: ac(170px, 130px, 768, 1440) !important;
                max-width: unset;
                width: ac(300px, 150px, 768, 1440);
                height: ac(300px, 150px, 768, 1440);
                opacity: 1 !important;
            }

            &__decor--new {
                top: ac(90px, 70px) !important;
                bottom: auto !important;
                max-width: none;


                @media (max-width: 540px) {
                    width: ac(250px, 100px, 320, 768);
                    height: ac(250px, 100px, 320, 768);
                    left: auto !important;
                    right: 6%;
                    bottom: auto !important;
                    top: 85px !important;
                }
            }
            
            &__title {
                padding-bottom: 0px;
                color: var(--cl-tealish);
            }

            &__form {
                display: flex;
                /*justify-content: space-between;
                align-items: flex-end;*/
                justify-content: flex-start;
                flex-direction: column;
                align-items: flex-start;
                margin-top: ac(98px, 50px);
                padding-top: ac(56px, 40px);
                border-top: 2px solid var(--cl-tealish);

                input {
                    line-height: ac(53px, 42px);
                    margin-top: ac(10px, 5px);
                    border: 1px solid var(--cl-dark-blue);

                    &::placeholder {
                        font-weight: 400;
                        opacity: .5;
                    }
                }
            }

            &__form--blogs {
                border-top: none;
            }

            &__form-container {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                width: 100%;
            }

            &__form-filters {
                padding-top: ac(32px, 24px);
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-wrap: wrap;
                width: 100%;
                gap: 6px;
            }

            &__input-box {
                &--select {
                    display: flex;
                    align-items: center;

                    .hero__label {
                        font-size: ac(18px, 16px);
                        line-height: ac(20px, 18px);
                        margin-right: 12px;
                    }

                    .choices {
                        &::before {
                            top: 9px;
                            right: 0px;
                        }

                        .choices__inner {
                            background-color: transparent;
                            min-height: unset;
                            border-radius: 0 !important;
                            padding: 0 18px !important;
                        }

                        .choices__list {
                            &--single {
                                .choices__item {
                                    font-size: ac(18px, 16px);
                                    line-height: 28px;
                                }
                            }

                            &--dropdown {
                                border-radius: 0 !important;
                            }
                        }

                        .choices__list--dropdown .choices__item--selectable[data-select-text], .choices__list[aria-expanded] .choices__item--selectable[data-select-text] {
                            padding-top: 16px !important;
                        }
                    }
                }
            }

            &__label {
                font-size: ac(20px, 18px);
                font-weight: 600;
            }
        }
    }

    &--blogs-max {
        @media (max-width: 540px) {
            max-height: unset;
        }
    }

    &--country {
        height: auto;
        min-height: ac(660px, 500px);
        padding-top: ac(265px, 140px);

        .hero {
            &__bg {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: -1;
                mix-blend-mode: soft-light;
            }

            &__decor {
                left: calc(50% - ac(-50px, 70px)) !important;
                bottom: ac(-80px, -20px, 375, 768) !important;
                max-width: unset;
                width: ac(760px, 230px, 320, 1440);
                height: ac(765px, 230px, 320, 1440);
                opacity: 1 !important;
            }
            
            &__title {
                margin-top: 6px;
                padding-bottom: 0px;
            }

            &__suptitle {
                font-size: ac(40px, 20px);
                line-height: 137.5%;
                font-weight: 600;
                color: var(--cl-white);

                @mixin max-sm {
                    font-weight: 700;
                    line-height: 168%;
                }
            }

        }
    }

    &--team {
        padding-top: ac(231px, 150px);
        height: auto;
        background: transparent;
        overflow: visible;

        .hero {
            &__decor {
                left: calc(50% - ac(-176px, -120px)) !important;
                bottom: ac(-20px, 150px) !important;
                max-width: unset;
                width: ac(545px, 230px);
                height: ac(548px, 230px);
                opacity: .1 !important;
            }

            &__title-box {
                padding-bottom: 0;
                width: 100%;
                max-width: ac(800px, 470px);
            }
            
            &__title, &__subtitle, &__descr {
                color: var(--cl-tealish);
            }

            &__title {
                margin-bottom: ac(43px, 20px);
                padding-bottom: 0px;
            }

            &__subtitle {
                margin-top: 0;
                margin-bottom: ac(19px, 10px);
                font-family: var(--font-main);
                font-size: ac(25px, 20px);
                line-height: ac(39px, 30px);
            }

            &__descr {
                font-weight: 400;
                font-size: ac(22px, 18px);
                line-height: ac(32px, 24px);
                font-family: var(--font-sec);
            }
        }
    }

    &--services,
    &--why {
        height: auto;
        min-height: ac(660px, 540px);
        padding-top: ac(270px, 120px);

        .hero {
            &__title {
                padding-bottom: 0px;
            }

            &__img {
                img {
                    object-position: right bottom;
                    transform: scaleX(1);
                }
            }

            &__decor {
                left: calc(50% - ac(180px, 200px)) !important;
                bottom: ac(-80px, 40px, 375, 768) !important;
                max-width: unset;
                width: ac(760px, 230px, 320, 1440);
                height: ac(765px, 230px, 320, 1440);
                opacity: 1 !important;
            }
        }
    }

    &--why {
        .hero {
            &__img {
                max-height: 90%;
                left: 50%;
                bottom: 0;
                transform: translateX(calc(-50% + ac(150px, 20px, 320, 1440)));
                max-width: ac(743px, 358px, 375, 768);
                height: ac(589px, 305px, 375, 768);
                opacity: 1;
                visibility: visible;
            }
        }

        &.prof-sap-page {
            .hero {
                &__img {
                    @media (max-width: 640px) {
                        bottom: -40px;
                    }
                }
            }

            @media (max-width: 551px) {
                min-height: 600px;

                .hero {
                    &__img {
                        width: 90%;
                        bottom: -20px;

                    }
                }
            }
        }
    }

    &--services {
        .hero {
            &__img {
                max-height: 90%;
                left: 50%;
                bottom: 0;
                transform: translateX(calc(-50% + ac(167px, 20px, 320, 1440)));
                max-width: ac(743px, 358px, 375, 768);
                height: ac(589px, 285px, 375, 768);
                opacity: 1;
                visibility: visible;
            }
        }
    }

    &--article {
        height: auto;
        padding-top: ac(231px, 140px);
        padding-bottom: ac(20px, 0px);
        background: transparent;

        .hero {
            &__decor {
                left: calc(50% - ac(-10px, -100px)) !important;
                bottom: ac(0px, 20px) !important;
                max-width: unset;
                width: ac(628px, 250px);
                height: ac(531px, 250px);
                opacity: 1 !important;
            }

            &__title, &__info {
                color: var(--cl-blue);
            }

            &__info {
                margin-top: ac(33px, 20px);
                font-size: ac(18px, 16px);
                line-height: ac(25px, 22px);
                font-family: var(--font-sec);

                span {
                    display: inline-block;
                    margin: 0 9px;
                }

                a {
                    color: var(--cl-yellow);
                    display: inline-block;
                    text-decoration: underline;
                    word-break: break-word;
                    transition: .3s;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }

            &__contact {
                margin-right: 17px;
                margin-bottom: 10px;
                height: 24px;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center bottom;
                transition: .3s ease;
            }

            &__share-box {
                margin-top: ac(60px, 30px);
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                margin-right: -19px;
                margin-bottom: -10px;
        
                span {
                    margin-bottom: 9px;
                    width: 100%;
                    font-weight: 600;
                    font-size: ac(22px, 18px);
                    line-height: ac(32px, 26px);
                    font-family: var(--font-sec);
                }
        
                .hero__contact {
                    margin-right: 19px;
                }
        
                .hero__contact--in {
                    width: 24px;
                    background-image: url("data:image/svg+xml,%3Csvg width='24' height='23' viewBox='0 0 24 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.98 2.5C4.98 3.881 3.87 5 2.5 5C1.13 5 0.02 3.881 0.02 2.5C0.02 1.12 1.13 0 2.5 0C3.87 0 4.98 1.12 4.98 2.5ZM5 7H0V23H5V7ZM12.982 7H8.014V23H12.983V14.601C12.983 9.931 19.012 9.549 19.012 14.601V23H24V12.869C24 4.989 15.078 5.276 12.982 9.155V7Z' fill='%2304143A'/%3E%3C/svg%3E%0A");
        
                    &:hover {
                        background-image: url("data:image/svg+xml,%3Csvg width='24' height='23' viewBox='0 0 24 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.98 2.5C4.98 3.881 3.87 5 2.5 5C1.13 5 0.02 3.881 0.02 2.5C0.02 1.12 1.13 0 2.5 0C3.87 0 4.98 1.12 4.98 2.5ZM5 7H0V23H5V7ZM12.982 7H8.014V23H12.983V14.601C12.983 9.931 19.012 9.549 19.012 14.601V23H24V12.869C24 4.989 15.078 5.276 12.982 9.155V7Z' fill='%23E4B700'/%3E%3C/svg%3E%0A");
                    }
                }
        
                .hero__contact--x {
                    width: 22px;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='20' fill='none'%3E%3Cpath fill='%2304143A' d='M16.99 0h3.308l-7.227 8.26 8.502 11.24h-6.657l-5.214-6.817L3.736 19.5H.426l7.73-8.835L0 0h6.826l4.713 6.231L16.99 0Zm-1.161 17.52h1.833L5.83 1.876H3.863L15.829 17.52Z'/%3E%3C/svg%3E");
        
                    &:hover {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='20' fill='none'%3E%3Cpath fill='%23E4B700' d='M16.99 0h3.308l-7.227 8.26 8.502 11.24h-6.657l-5.214-6.817L3.736 19.5H.426l7.73-8.835L0 0h6.826l4.713 6.231L16.99 0Zm-1.161 17.52h1.833L5.83 1.876H3.863L15.829 17.52Z'/%3E%3C/svg%3E");
                    }
                }
        
                .hero__contact--msn {
                    width: 24px;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='18' fill='none'%3E%3Cpath fill='%2304143A' d='M12 9.713.015 0h23.97L12 9.713Zm0 2.574L0 2.562V18h24V2.562l-12 9.725Z'/%3E%3C/svg%3E");
        
                    &:hover {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='18' fill='none'%3E%3Cpath fill='%23E4B700' d='M12 9.713.015 0h23.97L12 9.713Zm0 2.574L0 2.562V18h24V2.562l-12 9.725Z'/%3E%3C/svg%3E");
                    }
                }
        
                .hero__contact--fb {
                    width: 12px;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='24' fill='none'%3E%3Cpath fill='%2304143A' d='M3 8H0v4h3v12h5V12h3.642L12 8H8V6.333C8 5.378 8.192 5 9.115 5H12V0H8.192C4.596 0 3 1.583 3 4.615V8Z'/%3E%3C/svg%3E");
        
                    &:hover {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='24' fill='none'%3E%3Cpath fill='%23E4B700' d='M3 8H0v4h3v12h5V12h3.642L12 8H8V6.333C8 5.378 8.192 5 9.115 5H12V0H8.192C4.596 0 3 1.583 3 4.615V8Z'/%3E%3C/svg%3E");
                    }
                }
            }
        }
    }

    &--talent {
        height: auto;
        padding-top: ac(231px, 140px);
        background: var(--cl-white);
        overflow: hidden;

        .hero {
            &__decor {
                left: calc(50% - ac(-176px, -120px)) !important;
                bottom: ac(28px, 150px) !important;
                max-width: unset;
                width: ac(545px, 230px);
                height: ac(548px, 230px);
                opacity: 1 !important;
                @media (max-width: 768px) {
                    opacity: 0.1!important;
                }

                &.hero__decor--view {
                    opacity: 0.1!important;
                    &.animated {
                        opacity: 0.1!important;
                    }
                }
            }

            &__title {
                color: var(--cl-dark-blue);
                max-width: ac(940px, 488px);
                font-size: ac(40px, 26px);
                line-height: 137.5%;
                font-weight: 600;

                @media (max-width: 640px) {
                    font-weight: 700;
                    line-height: 168%;
                }

                @media (max-width: 768px) {

                }
            }

            &__info-box {
                margin-top: ac(40px, 25px);
                display: grid;
                grid-template-columns: max-content auto;
                row-gap: ac(12px, 8px);
            }

            &__info {
                &:nth-child(odd) {
                    margin-right: ac(75px, 15px, 320, 1440);
                    font-size: ac(22px, 16px);
                    line-height: ac(39px, 24px);
                    font-family: var(--font-sec);
                    color: var(--cl-yellow);
                }

                &:nth-child(even) {
                    font-size: ac(25px, 18px);
                    line-height: ac(39px, 24px);
                    font-weight: 600;
                    color: var(--cl-blue)
                }

            }
        }
    }

    &--details {
        height: auto;
        padding-top: ac(231px, 140px);
        background: var(--cl-white);
        overflow: hidden;
        max-height: unset;

        .hero__input-box:not(.hero__input-box--select) {
            max-width: ac(628px, 300px);
            width: 100%;
        }

        .hero {
            &__decor {
                left: calc(50% - ac(-176px, -120px)) !important;
                bottom: ac(28px, 150px) !important;
                max-width: unset;
                width: ac(545px, 230px);
                height: ac(548px, 230px);
                opacity: 1 !important;
            }

            &__title-box {
                padding-bottom: 0;
                width: 100%;
                max-width: ac(860px, 470px);

                .btn {
                    margin-top: ac(44px, 20px);
                }
            }
            
            &__title, &__subtitle {
                color: var(--cl-tealish);
            }

            &__title {
                margin-bottom: ac(43px, 20px);
                padding-bottom: 0px;
                color: var(--cl-dark-blue);
                max-width: ac(940px, 488px);
                font-size: ac(40px, 26px);
                line-height: 137.5%;
                font-weight: 600;

                @media (max-width: 640px) {
                    font-weight: 700;
                    line-height: 168%;
                }
            }

            &__subtitle {
                margin-top: 0;
                margin-bottom: ac(15px, 10px);
                font-family: var(--font-main);
                font-size: ac(25px, 20px);
                line-height: ac(39px, 30px);
                font-weight: 600;
                color: var(--cl-blue);

                p {
                    display: inline-block;
                    /*margin: 0 ac(15px, 10px);*/
                }
            }

            &__list {
                margin-top: 0;
                margin-bottom: ac(15px, 10px);
                font-family: var(--font-main);
                font-size: ac(25px, 20px);
                line-height: ac(39px, 30px);
                font-weight: 600;
                color: var(--cl-blue);

                display: grid;
                grid-template-columns: ac(210px, 175px) repeat(1, minmax(0, 1fr));
                grid-column-gap: ac(20px, 16px);
                grid-row-gap: ac(10px, 8px);

                @media (max-width: 425px) {
                    grid-template-columns: 140px repeat(1, minmax(0, 1fr));
                    font-size: 16px;
                }
            }
        }
    }

    @mixin lg {
        &:not(.hero--article, .hero--referral, .hero--team, .hero--why, .hero--services, .hero--country, .hero--blogs, .hero--talent-filter, .hero--jobs, .hero--talent, .hero--details) {
            padding-top: 30vh;

            .hero__img {
                &--first {
                    img {
                        object-position: ac(0px, -150px, 1024, 1440) bottom;
                    }
                }
            }
        }
    }

    @mixin max-lg {
        &:not(.hero--article, .hero--referral, .hero--team, .hero--why, .hero--services, .hero--country, .hero--blogs, .hero--talent-filter, .hero--jobs, .hero--details, .hero--talent) {
            .hero__img {
                left: calc(50% - 27vw);
                transform: unset;
            }
        }
    }

    @mixin max-md {
        &--blogs {
            .hero__input-box:not(.hero__input-box--select) {
                max-width: unset;
            }

            .hero__decor {
                bottom: 190px !important;
            }

            .hero__input-box--select {
                margin-left: auto;
            }

            .hero__form {
                flex-wrap: wrap;

                .btn {
                    margin-left: 0;
                    margin-top: 15px;
                    margin-right: 10px;
                }
            }

            .hero__form-container {
                flex-wrap: wrap;
            }

            .hero__input-box {
                &--select {
                    .choices {
                        &::before {
                            top: 8px;
                        }
                    }
                }
            }
        }

        &:not(.hero--article, .hero--referral, .hero--team, .hero--why, .hero--services, .hero--country, .hero--blogs, .hero--talent-filter, .hero--jobs, .hero--details, .hero--talent) {
            height: calc(calc(var(--vh) * 100) + 180px);
            max-height: calc(750px + 94px);
            min-height: 770px;
            .hero__bottom {
                padding-bottom: 0;

                .cont {
                    flex-direction: column-reverse;
                }
            }

            .hero__title-box {
                display: flex;
                flex-direction: column;
                height: 100%;
            }

        
            .hero__subtitle {
                display: flex;
                flex-direction: column;
                margin-top: 4vh;

                span {
                    &:not(:last-child) {
                        margin-bottom: ac(20px, 10px);
                    }
                }
            }

            .hero__btn-box {
                margin-left: 0;
                justify-content: flex-start;
            }

            .hero__slider-box {
                background-color: var(--cl-tealish);
                margin-top: 30px;
                margin-right: 0;
                max-width: unset;
                width: calc(100% + 40px);
                margin-left: -20px;
                padding: 30px 20px 30px;
            }

            .hero__nav {
                left: 20px;
                bottom: 31px;
            }
        }

        &--details, &--team, &--talent-filter, &--jobs, &--referral, &--talent {
            .hero__title-box {
                max-width: unset;
            }

            .hero__decor {
                top: 50% !important;
                right: 5%;
                left: unset !important;
                bottom: unset !important;
                width: ac(300px, 200px, 320, 768);
                height: ac(300px, 200px, 320, 768);
                transform: translateY(-50%) !important;
            }
        }

        &--team, &--jobs, &--talent-filter {
            .hero__decor {
                width: ac(250px, 100px, 320, 768);
                height: ac(250px, 100px, 320, 768);
                top: 150px !important;
            }
        }
        &--details {
            .hero__decor {
                opacity: .3 !important;
            }
        }

        &--referral {
            .hero__decor {
                display: none;
            }

            .hero__wrapper {
                flex-direction: column;
            }
        }

        &--article {
            padding-bottom: ac(350px, 290px, 320, 768);
            max-height: unset;
            .hero__title-box {
                max-width: unset;
            }

            .hero__decor {
                left: 50% !important;
                bottom: 0 !important;
                width: ac(350px, 280px, 320, 768);
                height: ac(350px, 250px, 320, 768);
                transform: translateX(-50%) !important;
            }
        }
    }
 
    @media (orientation: landscape) and (max-width: 900px) {
        &:not(.hero--article, .hero--referral, .hero--team, .hero--why, .hero--services, .hero--country, .hero--blogs, .hero--talent-filter, .hero--jobs, .hero--details, .hero--talent) {
            /* height: auto; */
            padding-top: 95px;
    
            .hero__img {
                left: calc(50% - 40vw);
            }
        }
    }

    @mixin max-sm {

        &--talent-filter,
        &--jobs {
            .hero__img {
                display: none;
            }

            .hero__form {
                max-width: unset;

                .hero__submit-box {
                    margin: 0 auto;

                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;

                    &>.btn {
                        margin: 10px 5px 0;
                    }
                }
            }

            .hero__clear-btn {
                transform: unset;
            }

            .hero__input-box:not(.hero__input-box--select), .hero__input-box--select {
                width: 100%;
            }
        }
    }

    @mixin max-xs {
        &__btn {
            margin-right: 15px;
    
            .arrow-btn {
                width: 40px;
                height: 40px;
            }
            .arrow-btn__icon {
                width: 20px;
                height: 20px;
            }
        }

        &__btn-text {
            margin-right: 10px;
            line-height: 40px;
            font-size: 16px;
        }

        &__descr {
            font-size: 16px;
        }

        &__pos {
            font-size: 12px;
        }
    }

    &.sap-talent-page {
        padding-top: ac(110px, 120px) !important;
        min-height: unset;
        height: auto;

        .hero__title-box {
            padding-bottom: 40px;
        }

        .future__text {
            width: 100%;
            max-width: 600px;
        }

        .future__text {
            h3 {
                margin-top: 0 !important;
            }
        }

        .future__text-descr {
            font-size: ac(20px, 18px);
            font-weight: 500;

            span {
                background: var(--cl-yellow);
                color: var(--cl-dark-blue);
            }
        }

        .future__row {
            padding-top: 14px;
            padding-bottom: 14px;

            &:before {
                top: 50%;
                transform: translateY(-50%);

            }
        }

        @media (max-width: 768px) {
            flex-direction: column-reverse !important;
            height: unset !important;
            max-height: unset !important;

            .hero__img.hero__img--first.hero-maintenance-img {
                position: relative !important;
                bottom: auto !important;
                top: auto !important;
            }
        }
    }
}